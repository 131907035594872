<template>
    <div>
      <button
        class="d-none"
        data-toggle="modal"
        data-target="#MyEventsDetailsPopup"
        ref="openMyEventDetails"
      ></button>
      <div class="modal fade" tabindex="-1" role="dialog" id="MyEventsDetailsPopup">
        <div class="modal-dialog custom-popup" role="document">
          <div class="modal-content">
            <div class="modal-body">
                <div class="popup-left-sec">
                    <h6 class="left-heading">Event Details</h6>
                    <div class="event-info-wrapper">
                        <div class="event-info-sec">
                            <div class="event-sec-top">
                                <div class="sec-date-name">
                                    <h6>Mar 8 to Mar 10, 2024 (Eastern Time)</h6>
                                    <span>Bart Adams March Madness II</span>
                                </div>
                                <div class="add-game-wrap">
                                    <button>Add Game</button>
                                </div>
                            </div>
                            <div class="event-sec-details">
                                <div class="event-sec-left">
                                    <span class="asso-tour">SUSSA - Tournament</span>
                                    <span class="director">Director: TomWhitesides</span>
                                </div>
                                <div class="event-sec-right">
                                    <div class="game-wrapper">
                                        <div class="total-game">
                                            <span>G</span>
                                            <span>0</span>
                                        </div>
                                        <div class="win-game">
                                            <span>W</span>
                                            <span>0</span>
                                        </div>
                                        <div class="loss-game">
                                            <span>L</span>
                                            <span>0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-container">
                        <!-- Left section -->
                        <div class="left-section">
                            <div class="live-box">
                                <button>Live</button>
                                <div class="seeding">Seeding 1</div>
                            </div>
                            <div class="timestamp">Sep 01, 04:30 PM</div>
                            <div class="group-link">
                                <img :src="people_outlined" alt="team" />
                                <span class="group-name">D'Amore Lara Group</span>
                            </div>
                            <div class="subtitle">Hit hour before into cages...</div>
                        </div>
                        <div class="right-section">
                            <div class="loc-content">
                                <img :src="location" alt="location" />
                                <span>Bullhead City, AZ</span>
                            </div>
                            <div class="reference-number">W21 - 19 Starts at 4:30 PM</div>
                        </div>
                    </div>
                </div>
                <div class="popup-right-sec">
                    <div class="custom-cross-wrap">
                        <button
                        type="button"
                        class="custom-cross"
                        data-dismiss="modal"
                        aria-label="Close"
                        ref="closeMyEventDetailsPopup"
                        >
                            <img :src="close" alt="close" />
                        </button>
                    </div>
                    <div class="attendee-sec">
                        <h6>Are you going ?</h6>
                        <div class="attendee-btns">
                            <button type="button" class="active">
                                <span>Going</span>
                            </button>
                            <button type="button">
                                <span>Not Going</span>
                            </button>
                            <button type="button">
                                <span>Maybe</span>
                            </button>
                        </div>
                        <div class="rsvp-container">
                            <!-- Status Tabs -->
                            <div class="status-tabs">
                            <div class="tab-item" :class="{ active: true }">
                                <span>Going</span>
                                <span class="counter">1</span>
                            </div>
                            <div class="tab-item">
                                <span>Not Going</span>
                                <span class="counter">1</span>
                            </div>
                            <div class="tab-item">
                                <span>Maybe</span>
                                <span class="counter">10</span>
                            </div>
                            <div class="tab-item">
                                <span>Not Responded</span>
                                <span class="counter">2</span>
                            </div>
                            </div>

                            <!-- People List -->
                            <div class="people-list">
                            <div v-for="person in people" :key="person.id" class="person-item">
                                <div class="attendee-people-info">
                                    <div class="people-info-img">
                                        <img :src="member" alt="member" />
                                    </div>
                                    <span class="person-name">{{ person.name }}</span>
                                </div>
                                <img :src="ellipse" alt="ellipse" class="show-options" />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "MyEventDetailsPopup",
    data() {
        return {
            close: require("../../../assets/Svg/close.svg"),
            location: require("../../../assets/Svg/location.svg"),
            member: require("../../../assets/Svg/member.svg"),
            ellipse: require("../../../assets/Svg/ellipse.svg"),
            people_outlined: require("../../../assets/Svg/people-outlined.svg"),
            people: [
                { id: 1, name: 'David Melton (Dave)', status: 'going' },
                { id: 2, name: 'Bruce Whitesides', status: 'not-going' },
                { id: 3, name: 'Murray Wortman', status: 'maybe' },
                { id: 4, name: 'Paul Ohlen', status: 'not-responded' },
                { id: 5, name: 'Kenneth Graham', status: 'not-responded' }
            ]
        }
    },
    mounted() {
        this.$root.$on("openMyEventDetailsPopup", () => {
            var elem = this.$refs.openMyEventDetails;
            if(elem)
            {
                elem.click();
            }
        });
    },
  };
  </script>
  