<template>
    <div class="filter-container">
      <!-- Year Dropdown -->
       <div class="filter-cotent">
         <!-- Organization Dropdown (Multi-select) -->
         <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('age')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedAges
        }"
          @click="toggleDropdown('age')"
        >
          <span>{{ selectedAges.length === 1 ? selectedAges[0] : '30 Older' }}</span>
          <div class="badge-wrapper" v-if="selectedAges.length > 1">
            <span class="counter-badge">+{{ selectedAges.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'age'">
          <div
            v-for="age in ages"
            :key="age"
            class="dropdown-item"
            :class="{ 'selected': selectedAges.includes(age) }"
            @click="toggleAgeSelection(age)"
          >
            <input 
              type="checkbox" 
              :checked="selectedAges.includes(age)"
              class="checkbox"
            >
           <span> {{ age }} </span>
          </div>
        </div>
      </div>
  
      <!-- Organization Dropdown (Multi-select) -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('team')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedTeams
        }"
          @click="toggleDropdown('team')"
        >
          <span>{{ selectedTeams.length === 1 ? selectedTeams[0] : 'Major 30+' }}</span>
          <div class="badge-wrapper" v-if="selectedTeams.length > 1">
            <span class="counter-badge">+{{ selectedTeams.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'team'">
          <div
            v-for="team in teams"
            :key="team"
            class="dropdown-item"
            :class="{ 'selected': selectedTeams.includes(team) }"
            @click="toggleTeamSelection(team)"
          >
            <input 
              type="checkbox" 
              :checked="selectedTeams.includes(team)"
              class="checkbox"
            >
           <span> {{ team }} </span>
          </div>
        </div>
      </div>

      <div class="dropdown-wrapper">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedGender
        }"
          @click="toggleDropdown('gender')"
        >
          <span>{{ selectedGender || 'Gender' }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'gender'">
          <div
            v-for="gender in genders"
            :key="gender"
            class="dropdown-item"
            @click="selectGender(gender)"
          >
            <span>{{gender}}</span>
          </div>
        </div>
      </div>
  
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('state')">
  <button 
    class="dropdown-button"
    :class="{
    'has-value': selectedStates.length > 0
  }"
    @click="toggleDropdown('state')"
  >
  <span>{{ selectedStates.length === 1 ? selectedStates[0] : 'Naveda' }}</span>
          <div class="badge-wrapper" v-if="selectedStates.length > 1">
            <span class="counter-badge">+{{ selectedStates.length - 1 }}</span>
          </div>
    <img :src="arrow_down" alt="arrow-down" />
  </button>
  <div class="dropdown-menu-content" v-show="activeDropdown === 'state'">
    <div
      v-for="state in states"
      :key="state"
      class="dropdown-item"
      :class="{ 'selected': selectedStates.includes(state) }"
      @click="toggleStateSelection(state)"
    >
      <input 
        type="checkbox" 
        :checked="selectedStates.includes(state)"
        class="checkbox"
      >
      <span>{{ state }}</span>
    </div>
  </div>
</div>
  
      <button class="filter-button">All Filters</button>
    </div>
      <button class="reset-button" @click="resetFilters">Reset Filters</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DiscoverTeamsFilter',
    directives: {
      'click-outside': {
        bind(el, binding) {
          el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
              binding.value(event);
            }
          };
          document.addEventListener('click', el.clickOutsideEvent);
        },
        unbind(el) {
          document.removeEventListener('click', el.clickOutsideEvent);
        },
      },
    },
    data() {
      return {
        activeDropdown: null,
        selectedAges: ['30 Older'],
        selectedTeams: ['Major 30+'],
        selectedGender: '',
        selectedStates: [],
        ages: ['30 Older', '40 Older', '50 Older'],
        teams: ['Dudley Lightning', 'Major 30+', 'Major 40+'],
        genders: [
          'Male',
          'Female',
          'Custom'
        ],
        states: ['Nevada', 'California', 'Arizona', 'Texas'],
        arrow_down: require("../../../assets/Svg/arrow-down.svg"),
      };
    },
    methods: {
      toggleDropdown(dropdownName) {
        this.activeDropdown = this.activeDropdown === dropdownName ? null : dropdownName;
      },
      closeDropdown(dropdownName) {
        if (this.activeDropdown === dropdownName) {
          this.activeDropdown = null;
        }
      },
      toggleAgeSelection(age) {
        const index = this.selectedAges.indexOf(age);
        if (index === -1) {
          this.selectedAges.push(age);
        } else {
          this.selectedAges.splice(index, 1);
        }
      },
      toggleTeamSelection(team) {
        const index = this.selectedTeams.indexOf(team);
        if (index === -1) {
          this.selectedTeams.push(team);
        } else {
          this.selectedTeams.splice(index, 1);
        }
      },
      selectGender(gender) {
        this.selectedGender = gender;
        this.closeDropdown('gender');
      },
      toggleStateSelection(state) {
        const index = this.selectedStates.indexOf(state);
        if (index === -1) {
            this.selectedStates.push(state);
        } else {
            this.selectedStates.splice(index, 1);
        }
    },
      resetFilters() {
        this.selectedAges = '30 Older';
        this.selectedTeams = ['MAjor 30+'];
        this.selectedGender = '';
        this.selectedStates = [];
      },
    },
  };
  </script>