<template>
    <div class="filter-container">
      <!-- Year Dropdown -->
       <div class="filter-cotent">
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('year')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedYear
        }"
          @click="toggleDropdown('year')"
        >
          <span>{{ selectedYear }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'year'">
          <div
            v-for="year in years"
            :key="year"
            class="dropdown-item"
            @click="selectYear(year)"
          >
           <span> {{ year }} </span>
          </div>
        </div>
      </div>
  
      <!-- Organization Dropdown (Multi-select) -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('org')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedOrgs
        }"
          @click="toggleDropdown('org')"
        >
          <span>{{ selectedOrgs.length === 1 ? selectedOrgs[0] : 'SSUSA' }}</span>
          <div class="badge-wrapper" v-if="selectedOrgs.length > 1">
            <span class="counter-badge">+{{ selectedOrgs.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'org'">
          <div
            v-for="org in organizations"
            :key="org"
            class="dropdown-item"
            :class="{ 'selected': selectedOrgs.includes(org) }"
            @click="toggleOrgSelection(org)"
          >
            <input 
              type="checkbox" 
              :checked="selectedOrgs.includes(org)"
              class="checkbox"
            >
           <span> {{ org }} </span>
          </div>
        </div>
      </div>
  
      <!-- Division Dropdown (Multi-select) -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('division')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedDivisions
        }"
          @click="toggleDropdown('division')"
        >
          <span>{{ selectedDivisions.length === 1 ? selectedDivisions[0] : 'Dudley Lightening 65+' }}</span>
          <div class="badge-wrapper" v-if="selectedDivisions.length > 1">
            <span class="counter-badge">+{{ selectedDivisions.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'division'">
          <div
            v-for="division in divisions"
            :key="division"
            class="dropdown-item"
            :class="{ 'selected': selectedDivisions.includes(division) }"
            @click="toggleDivisionSelection(division)"
          >
            <input 
              type="checkbox" 
              :checked="selectedDivisions.includes(division)"
              class="checkbox"
            >
            <span>{{ division }}</span>
          </div>
        </div>
      </div>

      <!-- Type Dropdown -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('type')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedType
        }"
          @click="toggleDropdown('type')"
        >
          <span>{{ selectedType || 'Type' }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'type'">
          <div
            v-for="type in types"
            :key="type"
            class="dropdown-item"
            @click="selectType(type)"
          >
            <span>{{type}}</span>
          </div>
        </div>
      </div>
  
      <!-- Additional Filter Buttons -->
      <button class="filter-button">Past Events</button>
      <button class="filter-button">Going</button>
      <button class="filter-button">All Filters</button>
    </div>
      <button class="reset-button" @click="resetMyEventsFilter">Reset Filters</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MyEventsFilter',
    directives: {
      'click-outside': {
        bind(el, binding) {
          el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
              binding.value(event);
            }
          };
          document.addEventListener('click', el.clickOutsideEvent);
        },
        unbind(el) {
          document.removeEventListener('click', el.clickOutsideEvent);
        },
      },
    },
    data() {
      return {
        activeDropdown: null,
        selectedYear: '2025',
        selectedOrgs: ['SSUSA'],
        selectedDivisions: ['Dudley Lightening 65+'],
        selectedType: '',
        years: ['2025', '2024', '2023'],
        organizations: ['SSUSA', 'LVSSA', 'MSBL'],
        divisions: [
          'Dudley Lightening 65+',
          'Dudley Thunder 50+',
          'Dudley Storm 40+'
        ],
        types: ['Online', 'InPerson'],
        arrow_down: require("../../../assets/Svg/arrow-down.svg"),
      };
    },
    methods: {
      toggleDropdown(dropdownName) {
        this.activeDropdown = this.activeDropdown === dropdownName ? null : dropdownName;
      },
      closeDropdown(dropdownName) {
        if (this.activeDropdown === dropdownName) {
          this.activeDropdown = null;
        }
      },
      selectYear(year) {
        this.selectedYear = year;
        this.closeDropdown('year');
      },
      toggleOrgSelection(org) {
        const index = this.selectedOrgs.indexOf(org);
        if (index === -1) {
          this.selectedOrgs.push(org);
        } else {
          this.selectedOrgs.splice(index, 1);
        }
      },
      toggleDivisionSelection(division) {
        const index = this.selectedDivisions.indexOf(division);
        if (index === -1) {
          this.selectedDivisions.push(division);
        } else {
          this.selectedDivisions.splice(index, 1);
        }
      },
      selectType(type) {
        this.selectedType = type;
        this.closeDropdown('type');
      },
      resetMyEventsFilter() {
        this.selectedYear = '2025';
        this.selectedOrgs = ['SSUSA'];
        this.selectedDivisions = ['Dudley Lightening 65+'];
        this.selectedState = '';
      },
    },
  };
  </script>