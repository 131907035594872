<template>
    <div class="filter-container">
      <!-- Year Dropdown -->
       <div class="filter-cotent">
         <!-- Organization Dropdown (Multi-select) -->
         <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('age')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedAges
        }"
          @click="toggleDropdown('age')"
        >
          <span>{{ selectedAges.length === 1 ? selectedAges[0] : '30 Older' }}</span>
          <div class="badge-wrapper" v-if="selectedAges.length > 1">
            <span class="counter-badge">+{{ selectedAges.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'age'">
          <div
            v-for="age in ages"
            :key="age"
            class="dropdown-item"
            :class="{ 'selected': selectedAges.includes(age) }"
            @click="toggleAgeSelection(age)"
          >
            <input 
              type="checkbox" 
              :checked="selectedAges.includes(age)"
              class="checkbox"
            >
           <span> {{ age }} </span>
          </div>
        </div>
      </div>

      <div class="dropdown-wrapper">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedGender
        }"
          @click="toggleDropdown('gender')"
        >
          <span>{{ selectedGender || 'Gender' }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'gender'">
          <div
            v-for="gender in genders"
            :key="gender"
            class="dropdown-item"
            @click="selectGender(gender)"
          >
            <span>{{gender}}</span>
          </div>
        </div>
      </div>
    </div>
      <button class="reset-button" @click="resetFilters">Reset Filters</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MyTeamsFilter',
    directives: {
      'click-outside': {
        bind(el, binding) {
          el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
              binding.value(event);
            }
          };
          document.addEventListener('click', el.clickOutsideEvent);
        },
        unbind(el) {
          document.removeEventListener('click', el.clickOutsideEvent);
        },
      },
    },
    data() {
      return {
        activeDropdown: null,
        selectedAges: ['30 Older'],
        selectedGender: '',
        ages: ['30 Older', '40 Older', '50 Older'],
        genders: [
          'Male',
          'Female',
          'Custom'
        ],
        arrow_down: require("../../../assets/Svg/arrow-down.svg"),
      };
    },
    methods: {
      toggleDropdown(dropdownName) {
        this.activeDropdown = this.activeDropdown === dropdownName ? null : dropdownName;
      },
      closeDropdown(dropdownName) {
        if (this.activeDropdown === dropdownName) {
          this.activeDropdown = null;
        }
      },
      toggleAgeSelection(age) {
        const index = this.selectedAges.indexOf(age);
        if (index === -1) {
          this.selectedAges.push(age);
        } else {
          this.selectedAges.splice(index, 1);
        }
      },
      selectGender(gender) {
        this.selectedGender = gender;
        this.closeDropdown('gender');
      },
      resetFilters() {
        this.selectedAges = '30 Older';
        this.selectedGender = '';
      },
    },
  };
  </script>