<template>
  <div>
    <div class="single-box-score-wrap tournament-side d-block pr-3">
      <div class="single-box-score-left-side w-100">
        <div class="single-score-table single-box-score-wrapper">
          <div v-if="showShimmer == 0">
            <ScoreTop
              :singleGame="singleGame"
              :currentIsAdmin="currentIsAdmin"
              :currentIsUmpire="currentIsUmpire"
              :association_id="association_id"
              :scores="scores"
            />
            <content-placeholders
              :rounded="true"
              v-if="showShimmer == 1"
              class="mt-3"
            >
              <content-placeholders-text :lines="6" />
            </content-placeholders>
            <div class="inning-sec" v-if="showShimmer == 0 && this.scores.length != 0">
              <div class="teams-info-sec">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="invisible">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-left">
                      <td
                        scope="col"
                        class="text-capitalize ellipsis-cell"
                        v-if="this.scores.length != 0"
                      >
                       #{{ visitTeamSrNo }} {{ visitTeamName }}
                      </td>
                      <td v-else>-----</td>
                    </tr>
                    <tr class="text-left">
                      <td
                        scope="col"
                        class="text-capitalize ellipsis-cell"
                        v-if="this.scores.length != 0"
                      >
                      #{{ homeTeamSrNo }} {{ homeTeamName }}
                      </td>
                      <td v-else>-----</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="inning-info-sec">
                <table
                  class="table table-float-right"
                  v-if="this.scores.length != 0 && totalInnings > 1">
                  <thead>
                    <tr>
                      <th
                        class="text-center"
                        scope="col"
                        v-for="n in totalInnings - 1"
                        :key="n"
                      >
                        {{ n }}
                      </th>
                      <th class="text-center" scope="col">
                        {{ totalInnings }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        class="text-center pr-0"
                        v-for="inning in previousInningVisitScore"
                        :key="inning.id + 1000"
                      >
                        <div
                          class="cursor-pointer"
                          @click="
                            callPeviousScoreUpdateModal(
                              inning.inning_no,
                              inning.tournament_game_score_id,
                              inning.tournament_game_id
                            )
                          "
                        >
                          {{ inning.score == null ? "0" : inning.score }}
                        </div>
                      </td>
                      <td class="text-center pr-0">
                        <div :class="currentTeamType == 1 ? 'span' : ''">
                          <div v-if="currentTeamType === 1">
                            {{ currentInningVisitScore }}
                          </div>
                          <div
                            v-else
                            class="cursor-pointer"
                            @click="
                              callPeviousScoreUpdateModal(
                                currentInningVisitScoreArray.inning_no,
                                currentInningVisitScoreArray.tournament_game_score_id,
                                currentInningVisitScoreArray.tournament_game_id
                              )
                            "
                          >
                            {{ currentInningVisitScore }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center pr-0"
                        v-for="inning in previousInningHomeScore"
                        :key="inning.id + 2000"
                      >
                        <div
                          class="cursor-pointer"
                          @click="
                            callPeviousScoreUpdateModal(
                              inning.inning_no,
                              inning.tournament_game_score_id,
                              inning.tournament_game_id
                            )
                          "
                        >
                          {{ inning.score == null ? "0" : inning.score }}
                        </div>
                      </td>
                      <td class="text-center pr-0">
                        <div :class="currentTeamType == 2 ? 'span' : ''">
                          <div>{{ currentInningHomeScore }}</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  class="table table-float-right"
                  v-if="this.scores.length != 0 && totalInnings == 1"
                >
                  <thead>
                    <tr>
                      <th scope="col" class="text-center">1</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center pr-0">
                        <div :class="currentTeamType == 1 ? 'span' : ''">
                          <div v-if="currentTeamType === 1">
                            {{ currentInningVisitScore }}
                          </div>
                          <div
                            v-else
                            class="cursor-pointer"
                            @click="
                              callPeviousScoreUpdateModal(
                                currentInningVisitScoreArray.inning_no,
                                currentInningVisitScoreArray.tournament_game_score_id,
                                currentInningVisitScoreArray.tournament_game_id
                              )
                            "
                          >
                            {{ currentInningVisitScore }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center pr-0">
                        <div :class="currentTeamType == 2 ? 'span' : ''">
                          <div>{{ currentInningHomeScore }}</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="inning-score">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center">Runs</th>
                      <th scope="col" class="text-center">HR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="col" class="text-center pr-0">
                        {{ totalVisitScore }}
                      </td>
                      <td scope="col" class="text-center pr-0">
                        {{ totalVisitHR }}
                      </td>
                    </tr>
                    <tr>
                      <td scope="col" class="text-center pr-0">
                        {{ totalHomeScore }}
                      </td>
                      <td scope="col" class="text-center pr-0">
                        {{ totalHomeHR }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="score-run-tabs-wrapper"  v-if="scores.length != 0 && (currentIsAdmin === '1' || currentIsUmpire === '1')">
              <ScoreRunsTabs
                :currentIsAdmin="currentIsAdmin"
                :currentIsUmpire="currentIsUmpire"
                :gameFetchedright="singleGame"
                @passData="GetData($event)"
                @passHrData="GetHrData($event)"
                :totalInnings="totalInnings"
                :currentBattingTeam="currentBattingTeam"
                :currentTeamType="currentTeamType"
                :showShimmer="showShimmer"
                :homeTeamName="homeTeamName"
                :visitTeamName="visitTeamName"
                :currentInningHomeHR="currentInningHomeHRArray"
                :currentInningHomeScore="currentInningHomeScoreArray"
                :currentInningVisitHR="currentInningVisitHRArray"
                :currentInningVisitScore="currentInningVisitScoreArray"
                :scores="scores"
                :singleGame="singleGame"
              />
            </div>
          </div>
          <!-- Update Score Modal -->
          <div
            data-target="#AddScoresModal"
            data-toggle="modal"
            ref="openAddScores"
          ></div>
          <div
            class="modal fade"
            id="AddScoresModal"
            tabindex="-1"
            aria-labelledby="AddScoresModalLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="AddScoresModalLabel">
                    Update Scores
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="closeAddScoresModal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="game-name">
                    <span style="font-size: 15px">
                      {{ updateScoreTeamNameFlag }}
                      <b
                        >({{
                          updateScoreTeamTypeFlag == 1
                            ? "Visiting Team"
                            : "Home Team"
                        }})</b
                      >
                    </span>
                  </div>
                  <div class="game-name">
                    <span style="font-size: 15px">
                      Inning<b> ({{ updateScoreInningNoFlag }})</b>
                    </span>
                  </div>
                  <br />

                  <form @keyup.enter="savePreviousScore(0)" @submit.prevent>
                    <div class="form-group" id="fieldset-1" label-for="score">
                      <input
                        id="updateScoreRunFlag"
                        autocomplete="off"
                        type="text"
                        v-model="updateScoreRunFlag"
                        maxlength="2"
                        required
                        @keyup="errorNull()"
                        @keypress="validateNumber"
                        @input="handleInput"
                      />

                      <span class="placeholder">Enter Runs</span>
                    </div>
                    <div class="form-group" id="fieldset-1" label-for="hr">
                      <input
                        id="updateScoreHRFlag"
                        autocomplete="off"
                        type="text"
                        v-model="updateScoreHRFlag"
                        maxlength="2"
                        required
                        @keyup="errorNull()"
                        @keypress="validateNumber"
                        @input="handleInput"
                      />

                      <span class="placeholder">Enter HR</span>
                    </div>
                  </form>
                </div>
                <div class="modal-footer sign-in">
                  <b-button
                    class="auth-btn font-weight-normal text-white"
                    style="width: 100px"
                    v-if="isDisabledUpdateScore == true"
                    :disabled="true"
                  >
                    <span>Save </span>
                  </b-button>
                  <b-button
                    v-else
                    class="auth-btn font-weight-normal text-white"
                    style="width: 100px"
                    :disabled="isLoadingArrayScore[0]"
                    @click="savePreviousScore(0)"
                  >
                    <div
                      v-if="isLoadingArrayScore[0]"
                      class="lds-dual-ring"
                    ></div>
                    <span v-else> Save </span>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Score Modal -->
  </div>
</template>

<script>
import axios from "axios";
import ScoreTop from "./ScoreTop.vue";
import ScoreRunsTabs from "./ScoreRunsTabs.vue";
export default {
  name: "Score",
  components: { ScoreTop, ScoreRunsTabs },
  data() {
    return {
      currentRuns: 0,
      hr: 0,
      gameScores: [],
      toggleButton: false,
      previousInningVisitScore: [],
      previousInningVisitHR: [],
      previousInningHomeScore: [],
      previousInningHomeHR: [],
      currentInningHomeHRArray: {},
      currentInningHomeScoreArray: {},
      currentInningVisitHRArray: {},
      currentInningVisitScoreArray: {},
      currentInningVisitScore: 0,
      currentInningVisitHR: 0,
      currentInningHomeScore: 0,
      currentInningHomeHR: 0,
      homeTeamName: "",
      visitTeamName: "",
      totalHomeHR: 0,
      totalVisitHR: 0,
      totalHomeScore: 0,
      totalVisitScore: 0,
      totalInnings: 1,
      currentBattingTeam: "",
      currentTeamType: 0,
      updateScoreRunFlag: 0,
      updateScoreHRFlag: 0,
      updateScoreInningNoFlag: 0,
      updateScoreGameIdFlag: 0,
      updateScoreGameScoreIdFlag: 0,
      updateScoreTeamNameFlag: "",
      homeTeamSrNo: "",
      visitTeamSrNo: "",
      updateScoreTeamTypeFlag: 0,
      isLoadingArrayScore: [],
      isDisabledUpdateScore: false,
      gameList: {},
      user_id : localStorage.getItem("userId"),

    };
  },
  props: {
    currentIsAdmin: {
      type: String,
    },
    currentIsUmpire: {
      type: String,
    },
    singleGame: {
      type: Object,
    },
    showShimmer: {
      type: Number,
    },
    association_id: {
      type: Number,
    },
    scores: {
      type: Array,
    },
  },
  watch: {
    singleGame(newValue) {
      this.scoringCalculation(newValue.game_scores);
    },
    currentIsAdmin(newValue) {
      if (newValue === "1") {
        console.log("The user is an Admin");
      } else {
        console.log("The user is not an Admin");
      }
    },
    currentIsUmpire(newValue) {
      if (newValue === "1") {
        console.log("The user is an Umpire");
      } else {
        console.log("The user is not an Umpire");
      }
    },
  },
  methods: {
    GetData(data) {
      if (this.currentTeamType == 1) {
        this.totalVisitScore =
          this.totalVisitScore - this.currentInningVisitScore;
        this.currentInningVisitScore = data;
        this.totalVisitScore =
          this.totalVisitScore + this.currentInningVisitScore;
      } else {
        this.totalHomeScore = this.totalHomeScore - this.currentInningHomeScore;
        this.currentInningHomeScore = data;
        this.totalHomeScore = this.totalHomeScore + this.currentInningHomeScore;
      }
    },
    GetHrData(data) {
      if (this.currentTeamType == 1) {
        this.totalVisitHR = this.totalVisitHR - this.currentInningVisitHR;
        this.currentInningVisitHR = data;
        this.totalVisitHR = this.totalVisitHR + this.currentInningVisitHR;
      } else {
        this.totalHomeHR = this.totalHomeHR - this.currentInningHomeHR;
        this.currentInningHomeHR = data;
        this.totalHomeHR = this.totalHomeHR + this.currentInningHomeHR;
      }
    },
    callPeviousScoreUpdateModal(
      inning_no,
      tournament_game_score_id,
      tournament_game_id
    ) {
      this.isDisabledUpdateScore = false;
      this.updateScoreGameIdFlag = tournament_game_id;
      this.updateScoreInningNoFlag = inning_no;
      this.updateScoreGameScoreIdFlag = tournament_game_score_id;

      this.gameScores.forEach((gameS) => {
        if (gameS.id == tournament_game_score_id) {
          this.updateScoreTeamNameFlag = gameS.team.team_name;
          this.updateScoreTeamTypeFlag = gameS.team_type;
          gameS.game_innings.forEach((inning) => {
            // Previous Inning Score
            if (inning.inning_no == inning_no) {
              if (inning.inning_type == 1) {
                this.updateScoreHRFlag = inning.score;
              }
              if (inning.inning_type == 2) {
                this.updateScoreRunFlag = inning.score;
              }
            }
          });
        }
      });

      if (this.singleGame.locked != 1  && (this.currentIsAdmin == '1' || this.currentIsUmpire == '1') && (this.singleGame.scoring_user_id === null || String(this.singleGame.scoring_user_id) === String(this.user_id))) {
        
        var elem = this.$refs.openAddScores;
        if (elem) {
          elem.click();
        }
      }
    },
    validateNumber(event) {
      const char = String.fromCharCode(event.keyCode);
      if (!/^\d+$/.test(char)) {
        event.preventDefault();
      }
    },
    handleInput(event) {
      this[event.target.id] = event.target.value.replace(/\D/g, '');
    },
    // errorNull: function () {
    //   if (
    //     this.updateScoreRunFlag == "" ||
    //     this.updateScoreHRFlag == "" 
    //   ) {
    //     this.isDisabledUpdateScore = true;
    //   } else {
    //     this.isDisabledUpdateScore = false;
    //   }
    // },
    errorNull: function () {
      if (this.updateScoreRunFlag !== "" && this.updateScoreHRFlag !== "") {
          this.isDisabledUpdateScore = false;
      } else {
          this.isDisabledUpdateScore = true;
      }
    },
    async savePreviousScore(index) {
      if (this.isDisabledUpdateScore != true) {
        this.$set(this.isLoadingArrayScore, index, true);
        var formData = new FormData();
        formData.append("tournament_game_id", this.updateScoreGameIdFlag);
        formData.append("inning_no", this.updateScoreInningNoFlag);
        formData.append(
          "tournament_game_score_id",
          this.updateScoreGameScoreIdFlag
        );
        formData.append("run_score", this.updateScoreRunFlag);
        formData.append("hr_score", this.updateScoreHRFlag);
        await axios
          .post(
            process.env.VUE_APP_API_URL +
              "tournaments/game/updatePreviousScore",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 404) {
                this.$alert(response.data.message, "Error");
                this.$router.push({ name: "Teams" });
              }
            this.$set(this.isLoadingArrayScore, 0, false);
            var elem = this.$refs.closeAddScoresModal;
            if (elem) {
              elem.click();
            }
            this.$root.$emit("showTounamentGameScore");
          });
      }
    },
    async scoringCalculation(gameScores) {
      this.gameScores = [];
      this.totalInnings = 1;
      this.previousInningHomeScore = [];
      this.previousInningHomeHR = [];
      this.currentInningHomeHRArray = [];
      this.currentInningHomeScoreArray = [];
      this.totalHomeScore = 0;
      this.totalHomeHR = 0;
      this.currentInningHomeScore = 0;
      this.currentInningHomeHR = 0;
      this.homeScoreId = 0;
      this.homeHRId = 0;
      this.homeStrikes = 0;
      this.homeBalls = 0;
      this.homeOuts = 0;
      this.homeInning = 0;
      
      

      this.previousInningVisitScore = [];
      this.previousInningVisitHR = [];
      this.currentInningVisitHRArray = [];
      this.currentInningVisitScoreArray = [];
      this.totalVisitScore = 0;
      this.totalVisitHR = 0;
      this.currentInningVisitScore = 0;
      this.currentInningVisitHR = 0;
      this.visitScoreId = 0;
      this.visitHRId = 0;
      this.visitStrikes = 0;
      this.visitBalls = 0;
      this.visitOuts = 0;
      this.visitInning = 0;
      
      
      this.gameScores = gameScores;

      if (this.gameScores.length > 0) {
        const inning = this.gameScores[0].game_innings.length;
        this.totalInnings = inning / 2;

        // Visiting
        this.gameScores.forEach((gameS) => {
          if (gameS.team_type == 1) {
            this.visitTeamName = "";
            this.visitTeamSrNo = "";
            this.visitTeamName = gameS.team.team_name;
            this.visitTeamSrNo = gameS.teams.team_sr_no;
        
            this.currentBattingTeam = gameS.batting_flag == 1 ? gameS.team.team_name : "";
            this.currentTeamType = gameS.batting_flag == 1 ? gameS.team_type : 0;
            gameS.game_innings.forEach((inning) => {
              // Previous Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 1) {
                this.totalVisitScore = inning.score != null ? parseInt(this.totalVisitScore) + parseInt(inning.score) : this.totalVisitScore;
                this.previousInningVisitScore.push(inning);
              }
              // Previous Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 1) {
                this.totalVisitHR = inning.score != null ? parseInt(this.totalVisitHR) + parseInt(inning.score) : this.totalVisitHR;
                this.previousInningVisitHR.push(inning);
              }
              // Current Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 2) {
                this.visitScoreId = inning.id;
                this.currentInningVisitScore = inning.score;
                this.totalVisitScore = inning.score != null ? parseInt(this.totalVisitScore) + parseInt(inning.score) : this.totalVisitScore;
                this.currentInningVisitScoreArray = inning;
                this.visitBalls = inning.balls ?? 0;
                this.visitStrikes = inning.strikes ?? 0;
                this.visitOuts = inning.outs ?? 0;
                this.visitInning = inning.inning_type ?? 0;
              }
              // Current Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 2) {
                this.visitHRId = inning.id;
                this.currentInningVisitHR = inning.score;
                this.totalVisitHR = inning.score != null ? parseInt(this.totalVisitHR) + parseInt(inning.score) : this.totalVisitHR;
                this.currentInningVisitHRArray = inning;
                this.visitBalls = inning.balls ?? 0;
                this.visitStrikes = inning.strikes ?? 0;
                this.visitOuts = inning.outs ?? 0;
                this.visitInning = inning.inning_type ?? 0;
              }
            });
          }
        });

        // Home
        this.gameScores.forEach((gameS) => {
          if (gameS.team_type == 2) {
            this.homeTeamName = "";
            this.homeTeamSrNo = "";
            this.homeTeamName = gameS.team.team_name;
            this.homeTeamSrNo= gameS.teams.team_sr_no
            this.currentBattingTeam = gameS.batting_flag == 1 ? gameS.team.team_name : this.currentBattingTeam;
            this.currentTeamType = gameS.batting_flag == 1 ? gameS.team_type : this.currentTeamType;
            gameS.game_innings.forEach((inning) => {
              // Previous Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 1) {
                this.totalHomeScore = inning.score != null ? parseInt(this.totalHomeScore) + parseInt(inning.score) : this.totalHomeScore;
                this.previousInningHomeScore.push(inning);
              }
              // Previous Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 1) {
                this.totalHomeHR = inning.score != null ? parseInt(this.totalHomeHR) + parseInt(inning.score) : this.totalHomeHR;
                this.previousInningHomeHR.push(inning);
              }
              //  Current Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 2) {
                this.homeScoreId = inning.id;
                this.currentInningHomeScore = inning.score;
                this.totalHomeScore = inning.score != null ? parseInt(this.totalHomeScore) + parseInt(inning.score) : this.totalHomeScore;
                this.currentInningHomeScoreArray = inning;
                this.homeBalls = inning.balls ?? 0;
                this.homeStrikes = inning.strikes ?? 0;
                this.homeOuts = inning.outs ?? 0;
                this.homeInning = inning.inning_type ?? 0;
              }
              // Current Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 2) {
                this.homeHRId = inning.id;
                this.currentInningHomeHR = inning.score;
                this.totalHomeHR = inning.score != null ? parseInt(this.totalHomeHR) + parseInt(inning.score) : this.totalHomeHR;
                this.currentInningHomeHRArray = inning;
                this.homeBalls = inning.balls ?? 0;
                this.homeStrikes = inning.strikes ?? 0;
                this.homeOuts = inning.outs ?? 0;
                this.homeInning = inning.inning_type ?? 0;

              }
                  
            });
          }
        });
        this.showShimmer = 0;
      }

      this.$root.$emit(
        "updateScoreHrTournament",
          this.currentTeamType == 1 ? this.currentInningVisitHR : this.currentInningHomeHR,
          this.currentTeamType == 1 ? this.currentInningVisitScore : this.currentInningHomeScore,
          this.currentTeamType === 1 ? this.visitStrikes : this.homeStrikes,
          this.currentTeamType === 1 ? this.visitBalls : this.homeBalls,
          this.currentTeamType === 1 ? this.visitOuts : this.homeOuts,
          this.currentTeamType === 1 ? this.visitInning : this.homeInning,
      );
    },
//     async scoringCalculation(gameScores) {
//   // Initialize variables
//   this.gameScores = [];
//   this.totalInnings = 1;
//   this.previousInningHomeScore = [];
//   this.previousInningHomeHR = [];
//   this.currentInningHomeHRArray = [];
//   this.currentInningHomeScoreArray = [];
//   this.totalHomeScore = 0;
//   this.totalHomeHR = 0;
//   this.currentInningHomeScore = 0;
//   this.currentInningHomeHR = 0;
//   this.homeScoreId = 0;
//   this.homeHRId = 0;
//   this.totalVisitBalls = 0;
//   this.totalVisitStrikes = 0;
//   this.totalVisitOuts = 0;

//   this.previousInningVisitScore = [];
//   this.previousInningVisitHR = [];
//   this.currentInningVisitHRArray = [];
//   this.currentInningVisitScoreArray = [];
//   this.totalVisitScore = 0;
//   this.totalVisitHR = 0;
//   this.currentInningVisitScore = 0;
//   this.currentInningVisitHR = 0;
//   this.visitScoreId = 0;
//   this.visitHRId = 0;
//   this.totalHomeBalls = 0;
//   this.totalHomeStrikes = 0;
//   this.totalHomeOuts = 0;

//   this.gameScores = gameScores;

//   if (this.gameScores.length > 0) {
//     const inning = this.gameScores[0].game_innings.length;
//     this.totalInnings = inning / 2;

//     // Visiting Team Calculations
//     this.gameScores.forEach((gameS) => {
//       if (gameS.team_type == 1) {
//         this.visitTeamName = gameS.team.team_name;
//         this.visitTeamSrNo = gameS.teams.team_sr_no;
//         this.currentBattingTeam = gameS.batting_flag == 1 ? gameS.team.team_name : "";
//         this.currentTeamType = gameS.batting_flag == 1 ? gameS.team_type : 0;

//         gameS.game_innings.forEach((inning) => {
//           // Previous Inning Score and HR
//           if (inning.inning_type == 2 && inning.inning_status == 1) {
//             this.totalVisitScore += inning.score ? parseInt(inning.score) : 0;
//             this.previousInningVisitScore.push(inning);
//           } else if (inning.inning_type == 1 && inning.inning_status == 1) {
//             this.totalVisitHR += inning.score ? parseInt(inning.score) : 0;
//             this.previousInningVisitHR.push(inning);
//           }

//           // Current Inning Score and HR
//           if (inning.inning_type == 2 && inning.inning_status == 2) {
//             this.visitScoreId = inning.id;
//             this.currentInningVisitScore = inning.score;
//             this.totalVisitScore += inning.score ? parseInt(inning.score) : 0;
//             this.currentInningVisitScoreArray = inning;
//           } else if (inning.inning_type == 1 && inning.inning_status == 2) {
//             this.visitHRId = inning.id;
//             this.currentInningVisitHR = inning.score;
//             this.totalVisitHR += inning.score ? parseInt(inning.score) : 0;
//             this.currentInningVisitHRArray = inning;
//           }

//           // Balls, Strikes, and Outs
//           this.totalVisitBalls += inning.balls ? parseInt(inning.balls) : 0;
//           this.totalVisitStrikes += inning.strikes ? parseInt(inning.strikes) : 0;
//           this.totalVisitOuts += inning.outs ? parseInt(inning.outs) : 0;
//         });
//       }
//     });

//     // Home Team Calculations
//     this.gameScores.forEach((gameS) => {
//       if (gameS.team_type == 2) {
//         this.homeTeamName = gameS.team.team_name;
//         this.homeTeamSrNo = gameS.teams.team_sr_no;
//         this.currentBattingTeam = gameS.batting_flag == 1 ? gameS.team.team_name : this.currentBattingTeam;
//         this.currentTeamType = gameS.batting_flag == 1 ? gameS.team_type : this.currentTeamType;

//         gameS.game_innings.forEach((inning) => {
//           // Previous Inning Score and HR
//           if (inning.inning_type == 2 && inning.inning_status == 1) {
//             this.totalHomeScore += inning.score ? parseInt(inning.score) : 0;
//             this.previousInningHomeScore.push(inning);
//           } else if (inning.inning_type == 1 && inning.inning_status == 1) {
//             this.totalHomeHR += inning.score ? parseInt(inning.score) : 0;
//             this.previousInningHomeHR.push(inning);
//           }

//           // Current Inning Score and HR
//           if (inning.inning_type == 2 && inning.inning_status == 2) {
//             this.homeScoreId = inning.id;
//             this.currentInningHomeScore = inning.score;
//             this.totalHomeScore += inning.score ? parseInt(inning.score) : 0;
//             this.currentInningHomeScoreArray = inning;
//           } else if (inning.inning_type == 1 && inning.inning_status == 2) {
//             this.homeHRId = inning.id;
//             this.currentInningHomeHR = inning.score;
//             this.totalHomeHR += inning.score ? parseInt(inning.score) : 0;
//             this.currentInningHomeHRArray = inning;
//           }

//           // Balls, Strikes, and Outs
//           this.totalHomeBalls += inning.balls ? parseInt(inning.balls) : 0;
//           this.totalHomeStrikes += inning.strikes ? parseInt(inning.strikes) : 0;
//           this.totalHomeOuts += inning.outs ? parseInt(inning.outs) : 0;
//         });
//       }
//     });

//     this.showShimmer = 0;
//   }

//   // Emit updated scores and home runs
//   this.$root.$emit(
//     "updateScoreHrTournament",
//     this.currentTeamType == 1 ? this.currentInningVisitHR : this.currentInningHomeHR,
//     this.currentTeamType == 1 ? this.currentInningVisitScore : this.currentInningHomeScore,
//     this.currentTeamType == 1 ? this.totalVisitStrikes : this.totalHomeStrikes, // Strikes
//     this.currentTeamType == 1 ? this.totalVisitBalls : this.totalHomeBalls,     // Balls
//     this.currentTeamType == 1 ? this.totalVisitOuts : this.totalHomeOuts   
//   );
// }

  },
  mounted() {  
    this.scoringCalculation(this.singleGame.game_scores);
  },
};
</script>
