<template>
  <div>
    <DeleteTaskModal />

    <div class="row no-gutters">
      <div class="task-list-wrap col-12" :class="{ 'col-md-9': isPanelOpen, 'p-25': isPanelOpen }">

        <!-- Loader -->
        <div class="products-list-shim" v-if="showLoader1">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
          </content-placeholders>
          <content-placeholders class="mt-3" :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
          </content-placeholders>
        </div>

        <div class="no-products-in-shop mt-0" v-if="tasks.length === 0 && completedTasks.length === 0 && showLoader1 === 0">
          <img src="../../assets/images/push-notification.png" alt />
          <p class="header">No task found.</p>
          <p class="info">There are no tasks to display at this moment.</p>
        </div>

        <div class="task-table" v-if="!showLoader1">

          <b-table v-if="incompleteTasks.length > 0" :items="incompleteTasks" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-compare="customSort" hover responsive class="my-0 b-datatable">

            <template v-slot:cell(status)="{ item }">
              <input type="checkbox" class="custom-checkbox-task" v-model="item.isCompleted"
                @change="updateTaskStatus(item)" />
            </template>

            <template v-slot:cell(taskTitle)="{ item }">
              <div class="d-flex items-center justify-content-between">
                <span>{{ item.taskTitle || "--" }}</span>
                <div class="add-friend bg-transparent">
                  <i class="fas fa-info-circle cursor-pointer" @click="openPanel(item)"></i>
                  <span class="tool-tip-text">Open details</span>
                </div>
              </div>
            </template>

            <template v-slot:cell(dueDate)="{ item }">
              <div class="input-group date mb-2" :class="{ 'text-danger': isPastDate(item.dueDate) }">
                <div class="add-friend task-toggle bg-transparent">
                  <input type="date" placeholder="Add due date" class="form-control"
                    :class="{ 'text-danger': isPastDate(item.dueDate) }" v-model="item.dueDate"
                    @change="updateTaskDueDate($event.target.value, item)" />
                  <span class="tool-tip-text" v-if="isPastDate(item.dueDate)">Overdue</span>
                </div>
              </div>
            </template>

            <template v-slot:cell(important)="{ item }">
              <div class="add-friend bg-transparent">
                <i :class="item.isImportant === 1 || item.isImportant === true ? 'fas fa-star' : 'far fa-star'"
                  @click.stop="toggleImportant(item)" class="cursor-pointer">
                </i>
                <span class="tool-tip-text" v-if="item.isImportant === 1 || item.isImportant === true">Remove
                  Importance</span>
                <span class="tool-tip-text" v-else>Mark task as important</span>
              </div>
            </template>

          </b-table>

          <!-- Completed Section -->
          <h6 class="cursor-pointer mt-2" :class="{ 'mb-3': !isCompletedCollapsed }" v-if="completedTasks.length > 0" @click="isCompletedCollapsed = !isCompletedCollapsed">
            <i :class="isCompletedCollapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-down' "></i>
              Completed
            <span class="">{{ completedTasks.length }}</span>
          </h6>

          <div v-if="!isCompletedCollapsed" class="">

            <b-table v-if="completedTasks.length > 0" :items="completedTasks" :fields="fields" hover responsive 
              :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-compare="customSort"
              @row-clicked="(item, index, event, field) => openPanel(item, field)" class="my-0 b-datatable cursor-pointer">

              <template v-slot:cell(status)="{ item }">
                <input type="checkbox" class="custom-checkbox-task" checked @change="updateTaskStatus(item)" />
              </template>

              <template v-slot:cell(taskTitle)="{ item }">
                <div class="d-flex items-center justify-content-between">
                  <div :class="{ 'completed-step': item.isCompleted }">
                    {{ item.taskTitle || "--" }}
                  </div>
                  <div class="add-friend bg-transparent">
                    <i class="fas fa-info-circle cursor-pointer"></i>
                    <span class="tool-tip-text">Open details</span>
                  </div>
                </div>
              </template>

              <template v-slot:cell(dueDate)="{ item }">
                <div class="input-group date mb-2" :class="{ 'text-danger': isPastDate(item.dueDate) }">
                  <div class="add-friend task-toggle bg-transparent">
                    <input type="date" placeholder="Add due date" class="form-control"
                      :class="{ 'text-danger': isPastDate(item.dueDate) }" v-model="item.dueDate"
                      @change="updateTaskDueDate($event.target.value, item)" />
                    <span class="tool-tip-text" v-if="isPastDate(item.dueDate)">Overdue</span>
                  </div>
                </div>
              </template>

              <template v-slot:cell(important)="{ item }">
                <div class="add-friend bg-transparent">
                  <i :class="item.isImportant === 1 || item.isImportant === true ? 'fas fa-star' : 'far fa-star'"
                    @click.stop="toggleImportant(item)" class="cursor-pointer"></i>
                  <span class="tool-tip-text" v-if="item.isImportant === 1 || item.isImportant === true">Remove
                    Importance</span>
                  <span class="tool-tip-text" v-else>Mark task as important</span>
                </div>
              </template>

            </b-table>
          </div>
        </div>

        <!-- Side Panel -->
        <div class="col-12 col-md-3 side-panel" v-if="isPanelOpen">

          <!-- loader -->
          <div class="products-list-shim" v-if="taskLoader">
            <content-placeholders :rounded="true">
              <content-placeholders-img />
              <content-placeholders-heading />
            </content-placeholders>
            <content-placeholders class="mt-3" :rounded="true">
              <content-placeholders-img />
              <content-placeholders-heading />
            </content-placeholders>
          </div>

          <div class="side-panel-wrap" v-else-if="!taskLoader">
            <div class="side-panel-content">
              <div class="panel-top-info">
                <div class="info">

                  <div>
                    <input type="checkbox" class="custom-checkbox-task"
                      :checked="selectedTask.isCompleted === true || selectedTask.isCompleted === 1"
                      @change="updateTaskStatus(selectedTask);" />

                    <input ref="taskInput" type="text" @keyup.enter="updateTaskTitle" placeholder="Add a task"
                      :class="{ 'completed-step': selectedTask.isCompleted === true || selectedTask.isCompleted === 1 }"
                      v-model="selectedTask.taskTitle" class="user-input" maxlength="30" />
                  </div>

                  <div class="add-friend important bg-transparent">
                    <i :class="selectedTask.isImportant === 1 || selectedTask.isImportant === true ? 'fas fa-star' : 'far fa-star'"
                      @click.stop="toggleImportant(selectedTask)" class="cursor-pointer"></i>
                    <span class="tool-tip-text" v-if="selectedTask.isImportant === true || selectedTask.isImportant === 1">Remove Importance</span>
                    <span class="tool-tip-text" v-else>Mark task as important</span>
                  </div>

                </div>

                <div v-if="panelLoader">
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                  <content-placeholders class="mt-3" :rounded="true">
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                </div>

                <div v-else class="task-steps">
                  <div v-for="(step, index) in taskSteps" :key="index" class="step-item">

                    <input type="checkbox" @click="updateStepStatus(step)" v-model="step.isCompleted"
                      class="custom-checkbox-task" />

                    <input type="text" :class="{ 'user-input': true, 'completed-step': step.isCompleted === 1 }"
                      v-model="step.taskTitle" @keyup.enter="updateStepTaskTitle(step)" placeholder="Edit task title"
                      maxlength="30" />

                    <button class="delete-btn" @click="deleteTask(step.id)">
                      <div class="add-friend bg-transparent"> ×
                        <span class="tool-tip-text">Delete step</span>
                      </div>
                    </button>

                  </div>

                  <div class="step-item">

                    <input type="text" class="user-input" v-model="steps.text" placeholder="Add step"
                      @keyup.enter="addTaskStep(selectedTask)" maxlength="30" />

                    <!-- Show Add button only if steps.text has value -->
                    <button type="button" v-if="steps.text && steps.text.length > 0" class="add-task-btn"
                      @click="addTaskStep(selectedTask)"> Add
                    </button>

                  </div>

                </div>

              </div>
            </div>

            <div class="panel-inputs">

              <div class="input-group date mb-2">
                <input type="date" class="form-control" v-model="dueDate" @change="updateDueDate()"
                  placeholder="Add due date" :class="{ 'text-danger': isPastDate(dueDate) }"
                  :title="isPastDate(dueDate) ? 'Overdue' : ''" />
              </div>

              <div class="form-group">
                <textarea class="form-control" rows="3" v-model="selectedTask.notes" placeholder="Add note"
                  ref="noteTextarea" @input="adjustHeight" @keydown.enter.prevent="addNote(selectedTask)">
                </textarea>
              </div>

              <div class="file-wrap">

                <div v-if="panelLoader1">
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                  <content-placeholders class="mt-3" :rounded="true">
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                </div>

                <div class="file-upload" v-else>
                  <!-- File input for uploading a new file -->
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="fileInput" @change="handleFileUpload" />
                    <label class="custom-file-label" for="fileInput">
                      {{ selectedFile ? selectedFile.name : "No file chosen" }}
                    </label>
                  </div>

                  <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-primary mt-2 add-btn" @click="addFile(selectedTask)"
                      :disabled="!selectedFile"> Add
                    </button>
                  </div>

                  <ul>

                    <!-- Loop through selectedTask.media to display existing files -->
                    <li v-for="(media, index) in fetchedMedia" :key="'media-' + index">
                      <a :href="media.fileUrl" class="file-link" target="_blank">{{ media.filename }}</a>
                      <span class="file-type"> {{ media.filetype }}</span>
                      <button type="button" class="delete-btn" @click="deleteMedia(media)">
                        <div class="add-friend bg-transparent"> × <span class="tool-tip-text">Delete file</span>
                        </div>
                      </button>
                    </li>

                  </ul>

                </div>
              </div>
            </div>
          </div>

          <div class="side-panel-footer" v-if="!taskLoader">
            <div class="add-friend bg-transparent">
              <i class="fas fa-arrow-right fa-xs cursor-pointer" @click="closePanel"></i>
              <span class="tool-tip-text">Hide details view</span>
            </div>
            <p class="created-date"> Created at {{ selectedTask.created_at | formatDateOfBirth }} </p>
            <div class="add-friend delete-task bg-transparent">
              <img class="cursor-pointer" src="../../assets/images/trash.png" alt="" srcset=""
                @click="callModal(selectedTask)" />
              <span class="tool-tip-text">Delete task</span>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteTaskModal from "./DeleteTaskModal.vue";
import axios from "axios";

export default {
  name: "TaskList",
  components: {
    DeleteTaskModal,
  },
  data() {
    return {
      tasks: [],
      completedTasks: [],
      incompleteTasks: [],
      panel: false,
      showLoader1: 0,
      showLoader: false,
      panelLoader: true,
      taskLoader: false,
      panelLoader1: true,
      isPanelOpen: false,
      selectedTask: {},
      taskSteps: {},
      fetchedMedia: {},
      isCompletedCollapsed: false,
      fields: [
        { key: "status", label: "", sortable: false },
        { key: "taskTitle", label: "Title", sortable: true },
        { key: "dueDate", label: "Due Date", sortable: true },
        { key: "important", label: "Importance", sortable: true },
      ],
      sortBy: 'taskTitle', // Default column to sort by
      sortDesc: false,
      dueDate: "",
      isCompleted: "",
      steps: {
        text: "",
      },
      files: [],
      selectedFile: null,
      note: "",
    };
  },

  computed: {
    isTaskEmpty() {
      return this.steps.every((step) => !step.text.trim());
    },
  },
  watch: {
    selectedTask(newTask) {
      if (newTask) {
        this.$set(newTask, 'previousTitle', newTask.taskTitle); // Store the initial title
      }
    }
  },

  methods: {
    
    customSort(a, b, key) {
      if (key === "important") {
        // Sort by isImportant: true first, then false
        if (a.isImportant === b.isImportant) {
          return 0;
        }
        return a.isImportant ? -1 : 1;
      }
      // Use default sorting for other fields
      return null;
    },
    isPastDate(date) {
      if (!date) return false;
      const today = new Date();
      const selectedDate = new Date(date);
      // Compare dates (ignoring time)
      return selectedDate < today.setHours(0, 0, 0, 0);
    },
    openPanel(task) {
      this.selectedTask = task || {}; 
      this.steps = task.steps || []; 
      this.dueDate = task.dueDate || ""; 
      this.note = task.notes || ""; 

      console.log(this.selectedTask);

      this.fetchSteps();
      this.fetchMedia();

      this.isPanelOpen = true;
      this.$emit("panel-state", this.isPanelOpen);

    },
    closePanel() {
      this.isPanelOpen = false;
      this.$emit("panel-state", this.isPanelOpen);
    },
    async deleteMedia(media) {
      this.$confirm(
        `Are you sure you want to delete this file?`
      ).then(() => {
        this.panelLoader1 = true;
        const formData = new FormData();
        formData.append('media_id', media.id);

        axios.post(
          `${process.env.VUE_APP_API_URL}associationEvent/deleteTaskMedia`,
          formData
        )
          .then(response => {
            if (response.data.statusCode === 200) {
              this.$alert('File deleted successfully');
              // Fetch media to update the list
              this.fetchMedia();
            }
          })
          .catch(error => {
            console.error('Error deleting media:', error);
          });
      }).catch(() => {
        console.log('Media deletion canceled by the user.');
      });
    },
    async updateStepTaskTitle(step) {

      if (!step || !step.taskTitle.trim()) {
        this.$alert("Tasks step title can't be empty.");
        this.fetchSteps();
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}userTasks/updateTitle`,
          {
            task_id: step.id,
            title: step.taskTitle,
          }
        );

        if (response.data.statusCode === 200) {
          this.fetchSteps();
        } else {
          // Optionally handle API error response
          console.error('Failed to update task title:', response.data.message);
        }
      } catch (error) {
        console.error('Error updating task title:', error);
      }
    },
    async updateTaskTitle() {

      if (!this.selectedTask || !this.selectedTask.taskTitle.trim()) {
        this.$alert("Task title can't be empty.");
        this.selectedTask.taskTitle = this.selectedTask.previousTitle; // Restore previous title
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}userTasks/updateTitle`,
          {
            task_id: this.selectedTask.id,
            title: this.selectedTask.taskTitle,
          }
        );

        if (response.data.statusCode === 200) {
          this.closePanel();
          this.root.emit("updateTask")
        } else {
          console.error('Failed to update task title:', response.data.message);
        }
      } catch (error) {
        console.error('Error updating task title:', error);
      }
    },
    async deleteTask(taskId) {
      this.$confirm("Are you sure you want to delete this step?")
        .then(async () => {
          try {
            this.panelLoader = true;

            const response = await axios.post(`${process.env.VUE_APP_API_URL}userTasks/deleteTask`, {
              task_id: taskId,
            });

            if (response.data.statusCode === 200) {
              this.fetchedMedia = {};
              this.$alert("Step deleted successfully");
              this.fetchSteps(); // Refresh the task list
              console.log("Task deleted and steps refreshed");
            } else {
              console.error("Failed to delete task:", response.data.message);
            }
          } catch (error) {
            console.error("Error deleting task:", error);
            this.panelLoader1 = false;

          } finally {
            this.panelLoader = false;
          }
        })
        .catch(() => {
          console.log("Task deletion canceled by the user.");
        });
    },

    async toggleImportant(task) {
      try {
        if (this.selectedTask && this.selectedTask.id === task.id) {
          this.taskLoader = true;
        }
        const response = await axios.post(`${process.env.VUE_APP_API_URL}userTasks/toggleImportant`, {
          task_id: task.id,
        });
        if (this.selectedTask && this.selectedTask.id === task.id) {
          this.fetchTaskData(task.id);
        }

        // Update the task locally with the new isImportant status
        task.isImportant = response.data.data.isImportant;
        this.fetchTasks();

      } catch (error) {
        console.error("Error toggling importance:", error);
        this.$alert("Failed to toggle task importance. Please try again.");
      }
    },
    async addNote(taskId) {
      try {
        if (taskId.notes.trim() === "") {
          this.$alert("Note cannot be empty");
          return;
        }

        const response = await axios.post(`${process.env.VUE_APP_API_URL}userTasks/addNote`, {
          task_id: taskId.id,
          note: taskId.notes,
        });

        console.log(response, "add")
      } catch (error) {
        console.error("Error adding note:", error);
        this.$alert("Failed to add note. Please try again.");
      }
    },
    async updateDueDate() {
      try {
        this.selectedTask.dueDate = this.dueDate;

        const response = await axios.post(`${process.env.VUE_APP_API_URL}userTasks/updateDueDate`, {
          task_id: this.selectedTask.id,
          dueDate: this.dueDate,
        });

        if (response.data.statusCode === 200) {
          this.fetchTasks();
          console.log("Due date updated successfully:", response.data);
        } else {
          console.warn("Failed to update due date.");
        }

      } catch (error) {
        console.error("Error updating due date:", error);
      }
    },
    updateTaskDueDate(selectedDate, item) {
      // Update the dueDate of the item with the selected date locally
      item.dueDate = selectedDate;
      console.log(`Updating due date for task ID ${item.id}: ${selectedDate}`);
      axios.post(`${process.env.VUE_APP_API_URL}userTasks/updateDueDate`, {
        task_id: item.id,
        dueDate: selectedDate,
      })
        .then((response) => {
          if (response.data.statusCode === 200) {

            // If the API call is successful, log success or perform additional actions
            console.log('Task due date updated successfully:', response.data);
            this.closePanel();
            this.fetchTasks();
          } else {
            console.warn('Unexpected response status:', response.status);
          }
        })
        .catch((error) => {
          // Handle API errors (e.g., network issues, server errors)
          console.error('Error updating task due date:', error);
        });
    },
    async updateTaskStatus(task) {
      try {
        if (this.selectedTask && this.selectedTask.id === task.id) {
          this.taskLoader = true;
        }

        const response = await axios.post(`${process.env.VUE_APP_API_URL}userTasks/toggleCompleted`, {
          task_id: task.id,
          isCompleted: task.isCompleted,
        });
        if (this.selectedTask && this.selectedTask.id === task.id) {
          this.fetchTaskData(task.id);
        }

        // Update the task locally with the new isCompleted status
        task.isCompleted = response.data.data.isCompleted;
        this.fetchTasks();
      } catch (error) {
        console.error("Error updating task status:", error);
        this.$alert("Failed to update task status. Please try again.");
      }
    },
    async updateStepStatus(step) {
      try {
        this.panelLoader = true;

        const response = await axios.post(`${process.env.VUE_APP_API_URL}userTasks/toggleCompleted`, {
          task_id: step.id,
          isCompleted: !step.isCompleted,
        });

        // Update the task locally with the new isCompleted status
        step.isCompleted = response.data.data.isCompleted;
        this.fetchSteps();
      } catch (error) {
        console.error("Error updating task status:", error);
        this.$alert("Failed to update task status. Please try again.");
      }
    },
    async fetchTaskData(taskId) {
      try {
        this.taskLoader = true;

        // Send the request to fetch task data
        const response = await axios.post(`${process.env.VUE_APP_API_URL}userTasks/fetchTaskData`, {
          task_id: taskId,
        });

        // Handle the response based on the status code
        if (response.data.statusCode === 200) {
          // Assign the fetched data to selectedTask if the status is successful
          this.selectedTask = response.data.data;
          console.log("updated_task", this.selectedTask);
        } else {
          // Handle any other status codes as needed (error or specific status)
          this.$alert(response.data.message || "Failed to fetch task data.");
        }
      } catch (error) {
        console.error("Error fetching task data:", error);
        this.$alert("Failed to fetch task data. Please try again.");
      } finally {
        this.taskLoader = false;
      }
    },

    adjustHeight() {
      const textarea = this.$refs.noteTextarea;
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },

    handleFileUpload(event) {
      const file = event.target.files[0]; // Get the first file

      // Validation for max file size (25MB)
      const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25MB in bytes
      if (file.size > MAX_FILE_SIZE) {
        this.$alert('File size exceeds the maximum limit of 25MB.');
        return;
      }

      // Allowed file types for non-images
      const allowedFileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
        'application/zip'
      ];

      // Allow all image types using a regex for "image/*"
      if (!file.type.startsWith('image/') && !allowedFileTypes.includes(file.type)) {
        this.$alert('Invalid file type. Allowed file types are: Images (all), PDF, DOC, DOCX, XLS, XLSX, CSV, ZIP.');
        return;
      }
      this.selectedFile = file ? file : null; // Assign to selectedFile
    },

    async addFile(selectedTask) {
      if (this.selectedFile) {
        this.files.push(this.selectedFile); // Add the selected file to the list
        try {
          await this.uploadFile(this.selectedFile, selectedTask); // Call upload function
          this.selectedFile = null; // Reset selected file
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    },
    async uploadFile(file, selectedTask) {
      this.panelLoader1 = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('filename', file.name);
      formData.append('filetype', file.type);
      formData.append('task_id', selectedTask.id);
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}userTasks/updateTasks`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response.data.statusCode === 200) {
          await this.fetchMedia();
          this.files = [];
        }

        return response.data;
      } catch (error) {
        this.panelLoader1 = false;
        throw new Error('File upload failed');
      }
    },
    callModal(selectedTask) {
      this.$root.$emit("openDeleteTaskModal", selectedTask);
    },
    toggleTaskCompletion(task, isCompleted) {

      if (isCompleted) {
        this.tasks.push(task);
        this.completedTasks = this.completedTasks.filter(t => t.id !== task.id);
      } else {
        this.completedTasks.push(task);
        this.tasks = this.tasks.filter(t => t.id !== task.id);
      }
    },
    async fetchTasks() {
      try {
        this.showLoader1 = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}userTasks/getTasks`
        );

        // Store all tasks in this.tasks
        this.tasks = response.data.data;
        this.incompleteTasks = this.tasks.filter(task => !task.isCompleted);
        this.completedTasks = this.tasks.filter(task => task.isCompleted);

        this.showLoader1 = 0; // Hide loader
      } catch (error) {
        this.showLoader1 = 0; // Hide loader in case of error
        console.error("Error fetching tasks:", error);
      }
    },

    async addTaskStep(selectedTask) {
      try {
        this.panelLoader = true;

        // Prepare form data
        const formData = new FormData();
        formData.append("parentId", selectedTask.id); // Assuming `selectedTask.id` is the task ID
        formData.append("taskTitle", this.steps.text);

        // Send the step to the server
        const response = await axios.post(`${process.env.VUE_APP_API_URL}userTasks/store`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Check if statusCode is 200
        if (response.data.statusCode === 200) {
          // If successful, add a new empty step and fetch steps
          this.steps.text = "";
          this.fetchSteps();
        } else {
          // Log the error to the console instead of showing an alert
          console.error("Failed to add task step. Status Code:", response.data.statusCode);
        }

      } catch (error) {
        // Log the error to the console instead of showing an alert
        console.error("Error adding task step:", error);
      }
    },

    async fetchSteps() {
      try {
        this.panelLoader = true;

        const formData = new FormData();
        formData.append("task_id", this.selectedTask.id);

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}userTasks/fetchSteps`,
          formData
        );
        if (response.data.statusCode === 200) {
          this.taskSteps = response.data.data;
        } else {
          console.error("Error fetching task steps. Status Code:", response.data.statusCode);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        // Hide the loader once the request is completed (success or error)
        this.panelLoader = false;
      }
    },
    async fetchMedia() {
      try {
        this.panelLoader1 = true;
        this.fetchedMedia = {};
        const formData = new FormData();
        formData.append("task_id", this.selectedTask.id);

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}userTasks/getTaskMedia`,
          formData
        );

        // Check if the statusCode is 200 before processing the response
        if (response.data.statusCode === 200) {
          this.fetchedMedia = response.data.data;
        } else {
          console.error("Error fetching task steps. Status Code:", response.data.statusCode);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
        this.panelLoader1 = false;

      } finally {
        // Hide the loader once the request is completed (success or error)
        this.panelLoader1 = false;
      }
    },





  },
  mounted() {
    this.fetchTasks();
    this.$root.$on('closePanel', () => {
      this.closePanel();
    });

    this.$root.$on("add-task", () => {
      this.tasks = [];
      this.fetchTasks();
    });
    this.$root.$on("delete-task", () => {
      this.tasks = [];
      this.fetchTasks();
    }); // Fetch tasks when the component is mounted
    this.$root.$on("updateTask", () => {
      this.tasks = [];
      this.completedTasks = [];
      this.fetchTasks();
    })
    this.$root.$on("taskDeleted", () => {
      this.tasks = [];
      this.completedTasks = [];
      this.fetchTasks();
    })
  },
};
</script>
<style scoped>
.panel {
  top: -23% !important;
}
</style>