<template>
    <div>
        <Navbar />
        <div class="setting-main-wrapper">
            <div class="main-left-menu">
                <LeftMenu />
            </div>
            <Banner />

            <div class="shop-side p-md-0">
                <LeftMenuSide :openTabSet="openTabSet" />
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import LeftMenuSide from "../components/NewGameTime/LeftMenuSide.vue";

export default {
    name: "NewGameTimePage",
    components: {
        Navbar,
        LeftMenu,
        Banner,
        LeftMenuSide
    },
    data() {
        return {
            openTabSet: 1,
        };
    },
    mounted() {
        this.$root.$on("openTabSetting", (id) => {
            this.openTabSet = id;
        });
    },
};
</script>