<template>
    <div>
        <Navbar />
        <div class="setting-main-wrapper">
            <div class="main-left-menu">
                <LeftMenu />
            </div>
            <Banner />

            <div class="shop-side p-md-0">
                <LeftMenuSide :openTabSet="openTabSet" />
                <div class="row no-gutters content-wrap-info">
                    <div class="event-section-wrap team-section-wrap">
                        <div class="event-section">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6>Discover Teams</h6>
                                <div class="form-inline bg-white">
                                    <button>
                                    <img class="search-image" :src="search" alt="search" />
                                    </button>
                                    <input v-model="searchTerm" aria-label="Search" class="form-control border-0 bg-white" placeholder="Search"
                                    type="search" v-on:keyup.enter="searchValue" />
                                </div>
                            </div>
                            <div class="event-section-content">
                                <div class="filter-top">
                                    <DiscoverTeamsFilter />
                                </div>
                                <div class="event-info-wrapper">
                                    <div class="event-info-sec">
                                        <div class="event-sec-top team-sec-top">
                                            <div class="team-sec-left">
                                                <div class="team-info-image">
                                                    <img :src="teams" alt="team" />
                                                </div>
                                                <div class="sec-date-name">
                                                    <h6>Okay City Mayhem</h6>
                                                    <span>65 Older - AAA - Male</span>
                                                    <div class="loc-content">
                                                        <img :src="location" alt="location" />
                                                        <span>Bullhead City, AZ</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="loc-follow">
                                                <div class="follow-unfollow-btns">
                                                    <button class="unfollow-btn">
                                                        <img :src="unfollow" alt="unfollow" />
                                                        <span>Follow</span>
                                                    </button>
                                                    <button class="follow-btn">
                                                        <img :src="follow" alt="follow" /> 
                                                    </button>
                                                </div>
                                                <div class="team-email-phone">

                                                    <div class="team-email">
                                                        <span>team@clubname.com</span>
                                                        <img :src="sms" alt="sms" />
                                                    </div>
                                                    <div class="team-number">
                                                        <span>+1 (786) 1234 567</span>
                                                        <img :src="phone" alt="phone" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import DiscoverTeamsFilter from "@/components/NewGameTime/Teams/DiscoverTeamsFilter.vue";

export default {
    name: "DiscoverTeams",
    components: {
        Navbar,
        LeftMenu,
        Banner,
        LeftMenuSide,
        DiscoverTeamsFilter,
    },
    data() {
        return {
            openTabSet: 2,
            location: require("../../../assets/Svg/location.svg"),
            unfollow: require("../../../assets/Svg/unfollow.svg"),
            follow: require("../../../assets/Svg/follow.svg"),
            search: require("../../../assets/Svg/Search.svg"),  
            teams: require("../../../assets/Svg/team.svg"),
            sms: require("../../../assets/Svg/sms.svg"),  
            phone: require("../../../assets/Svg/mobile.svg"),
        };
    },
    mounted() {
        this.$root.$on("openTabSetting", (id) => {
            this.openTabSet = id;
        });
    },
};
</script>