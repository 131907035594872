<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <GameTimeTab />
        <eventsDataList />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import GameTimeTab from "../components/GameTime/GameTimeTabs.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import eventsDataList from "../components/Events/eventsDataList.vue";

export default {
  data() {
    return {
      page: 1,
      lastPage: 1,
      eventsData: [],
      showLoader: 0,
      openTabSet: 1,
      
    };
  },
  components: {
    Navbar,
    LeftMenu,
    Banner,
    eventsDataList,
    GameTimeTab
    // LeftMenuSide
  },
    mounted() {
        this.$root.$on("openGameTimeTabSetting", (id) => {
            this.openTabSet = id;
        });
    },
};
</script>
