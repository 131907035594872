<template>
  <div class="secondary-left-menu">
    <ul class="left-menu-list">
      <!-- Events Menu -->
      <li class="left-list-item" v-bind:class="[openTabSet == 1 ? 'active' : '']" @click="toggleMenu(1)">
        <div class="list-item-content">
          <div class="item-content-image">
            <img v-if="openTabSet == 1" :src="event_active" />
            <img v-else :src="event_inactive" />
          </div>
          <span>Events</span>
        </div>
        <div class="list-item-toggle">
          <img v-if="submenuVisible == 1" :src="arrow_down" alt="arrow-down" />
          <img v-else :src="arrow_right" alt="arrow-right" />
        </div>
      </li>
      <ul v-if="submenuVisible == 1" class="submenu">
        <li>
          <router-link exact :to="{ name: 'Events' }" :class="{ 'active-link': isEventsActive }">Discover</router-link>
        </li>
        <li>
          <router-link exact :to="{ name: 'MyEvents' }" :class="{ 'active-link': isEventsActive }">My
            Events</router-link>
        </li>
        <li>
          <router-link exact :to="{ name: 'FollowingEvents' }"
            :class="{ 'active-link': isEventsActive }">Following</router-link>
        </li>
      </ul>

      <!-- Teams Menu -->
      <li class="left-list-item" v-bind:class="[openTabSet == 2 ? 'active' : '']" @click="toggleMenu(2)">
        <div class="list-item-content">
          <div class="item-content-image">
            <img v-if="openTabSet == 2" :src="team_active" />
            <img v-else :src="team_inactive" />
          </div>
          <span>Teams</span>
        </div>
        <div class="list-item-toggle">
          <img v-if="submenuVisible == 2" :src="arrow_down" alt="arrow-down" />
          <img v-else :src="arrow_right" alt="arrow-right" />
        </div>
      </li>
      <ul v-if="submenuVisible == 2" class="submenu">
        <li>
          <router-link exact :to="{ name: 'DiscoverTeams' }" :class="{ 'active-link': isMyTeamActive }">Discover</router-link>
        </li>
        <li>
          <router-link exact :to="{ name: 'MyTeams' }" :class="{ 'active-link': isMyTeamActive }">My Teams</router-link>
        </li>
        <li>
          <router-link exact :to="{ name: 'FollowingTeams' }"
            :class="{ 'active-link': isMyTeamActive }">Following</router-link>
        </li>
      </ul>

      <!-- Boxscores Menu -->
      <li class="left-list-item" v-bind:class="[openTabSet == 3 ? 'active' : '']" @click="toggleMenu(3)">
        <div class="list-item-content">
          <div class="item-content-image">
            <img v-if="openTabSet == 3" :src="boxscore_active" />
            <img v-else :src="boxscore_inactive" />
          </div>
          <span>Boxscores</span>
        </div>
        <div class="list-item-toggle">
          <img v-if="submenuVisible == 3" :src="arrow_down" alt="arrow-down" />
          <img v-else :src="arrow_right" alt="arrow-right" />
        </div>
      </li>
      <ul v-if="submenuVisible == 3" class="submenu">
        <li>
          <router-link exact :to="{ name: 'AllBoxScores' }">Discover</router-link>
        </li>
        <li>
          <router-link exact :to="{ name: 'MyBoxScores' }">My Boxscores</router-link>
        </li>
        <li>
          <router-link exact :to="{ name: 'FollowingBoxScores' }">Following</router-link>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LeftMenuSide",
  data() {
    return {
      arrow_right: require("../../assets/Svg/arrow-right.svg"),
      arrow_down: require("../../assets/Svg/arrow-down.svg"),
      event_active: require("../../assets/Svg/calendar-blue.svg"),
      event_inactive: require("../../assets/Svg/calendar-grey.svg"),
      team_active: require("../../assets/Svg/team-blue.svg"),
      team_inactive: require("../../assets/Svg/team-grey.svg"),
      boxscore_active: require("../../assets/Svg/box-score-blue.svg"),
      boxscore_inactive: require("../../assets/Svg/box-score-grey.svg"),
      submenuVisible: 1,
    };
  },
  props: {
    openTabSet: {
      type: Number,
    },
  },
  computed: {
    isMyTeamActive() {
      return (
        this.$route.name === "DiscoverTeams" ||
        this.$route.name === "MyTeams" ||
        this.$route.name === "FollowingTeams"
      );
    },
    isEventsActive() {
      return (
        this.$route.name === "Events" ||
        this.$route.name === "MyEvents" ||
        this.$route.name === "FollowingEvents"
      );
    },
    isBoxscoreActive() {
      return (
        this.$route.name === "AllBoxScores" ||
        this.$route.name === "MyBoxScores" ||
        this.$route.name === "FollowingBoxScores"
      );
    },
  },
  watch: {
    $route() {
      if (this.isEventsActive) {
        this.submenuVisible = 1;
      } else if (this.isMyTeamActive) {
        this.submenuVisible = 2;
      } else if (this.isBoxscoreActive) {
        this.submenuVisible = 3;
      } else {
        this.submenuVisible = 0;
      }
    },
  },
  methods: {
    toggleMenu(tab) {
      this.submenuVisible = this.submenuVisible === tab ? 0 : tab;
    },
  },
  mounted() {
    if (this.isEventsActive) {
      this.submenuVisible = 1;
    } else if (this.isMyTeamActive) {
      this.submenuVisible = 2;
    } else if (this.isBoxscoreActive) {
      this.submenuVisible = 3;
    }
  },
};
</script>
