<template>
  <div>
    <EditEventDetailModal
    :association_id="association_id"
        />
    <div class="row m-0">
      <div
        class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end"
      >
        <select
          class=""
          id="eventType"
          v-model="filter.eventType"
          @change="checkEventType()"
          :disabled="isLoading"
        >
          <option value="" selected>Select Event Type</option>
          <option
            v-for="option in filteredEventTypeOptions"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
        <span
          :style="{ display: isLoading ? 'none' : 'block' }"
          v-if="filter.eventType"
          class="clear-icon"
          @click="clearEventType()"
          ><img src="../../assets/cross-icon.png"
        /></span>
      </div>
      <!-- <div
        class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end"
      >
        <select
          class=""
          v-model="filter.stateFilter"
          @change="fetchEvents()"
          :disabled="isLoading"
        >
          <option value="" selected>Select States</option>
          <option
            v-for="option in stateOptions"
            :value="option"
            :key="option.name"
          >
            {{ option.name }}
          </option>
        </select>
        <span
          :style="{ display: isLoading ? 'none' : 'block' }"
          v-if="filter.stateFilter"
          class="clear-icon"
          @click="clearState()"
          ><img src="../../assets/cross-icon.png"
        /></span>
      </div> -->
      <div class="col-md-3 px-1 form-group mb-0">
        <date-picker
          :open="open"
          @close="open = false"
          :editable="false"
          :clearable="true"
          @click.native="handleClick"
          v-model="filter.startDateFilter"
          valueType="format"
          format="MM/YYYY"
          placeholder="mm/yyyy"
          @change="checkDateInput()"
          :disabled="isLoading"
           type="month"
        >
        
          <i slot="icon-calendar" class="cursor-pointer">
            <img src="../../assets/images/calendar-input.png" />
          </i>
        </date-picker>
      </div>
    </div>

    <div class="row" >
      <div class="w-100 px-3 d-flex justify-content-end">
        <CreateAssociationEventDetailModal :associationData="associationData"
        :association_id="association_id"
        :currentIsAdmin="currentIsAdmin"
        />
      </div>
    </div>

      <b-row class="no-gutters" v-if="associationEvents.length > 0">
        <div class="col-12 products-column">
          <div class="event-card">
            <div class="table-responsive mb-0">
                <b-table
                    :items="associationEvents"
                    :fields="fields"
                    hover
                    responsive
                    class="mt-3 b-datatable"
                    >
                    
                    <template v-slot:cell(eventName)="data">
                      <router-link v-if="currentIsAdmin" @click="handleRowClick(data.item)" :to="{ name: 'AssociationSingleEventDetail', params: { id: data.item.guid } }" class="stats-link m-0">
                          {{ data.item.eventName }}
                      </router-link>
                      <router-link v-else :to="{ name: 'EventGame', params: { id: data.item.guid } }" class="stats-link m-0">
                          {{ data.item.eventName }}
                      </router-link>
                    </template>
                    <template v-slot:cell(location)="data">
                        <div
                        class="event-location"
                        v-if="
                            (data.item.status == 1 &&
                            data.item.address != null &&
                            data.item.address != '' &&
                            data.item.address != 'null') ||
                            (data.item.city != null &&
                            data.item.city != '' &&
                            data.item.city != 'null') ||
                            (data.item.state != null &&
                            data.item.state != '' &&
                            data.item.state != 'null')
                        "
                        >
                        <img
                            class="mr-2"
                            src="../../assets/images/location.png"
                            @click="openGoogleMap(data.item)"
                        />
                        <span class="content" @click="openGoogleMap(data.item)">
                            {{ data.item.address }} {{ data.item.city }},
                            {{ data.item.state }}
                        </span>
                        </div>
                        <div v-else>
                        <!-- Display the medium property -->
                        Online Event
                        </div>
                    </template>
                    <template v-slot:cell(state)="data">
                        <div class="event-location" v-if="data.item.state !== null">
                        {{ data.item.state }}
                        </div>
                        <div v-else>Online Event</div>
                    </template>
                    <template v-slot:cell(director_name)="data">
                        <div class="event-location" v-if="data.item.director_name !== null">
                        {{ data.item.director_name }}
                        </div>
                        <div v-else>N/A</div>
                    </template>
                    <template v-slot:cell(action)="data">
                      <div class="d-flex justify-content-end" @click="toggleEditEventOption(data.item)" v-if="currentIsAdmin == '1'">
                        <div class="opt edit-btn mb-0">
                          <img src="../../assets/images/ellipsis-h.png" alt="" />
                          <div >
                            <div class="sub-setting-options edit-event-options" v-if="editEventOptionVisible && selectedItem === data.item">
                              <ul class="d-block pb-0">
                                <li class="cursor-pointer" @click="editEvent(data.item)">Edit Event</li>
                                <!-- <li class="cursor-pointer" @click="deleteEvent(data.item)">Delete</li> -->
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                      <span> N/A </span>
                    </div>
                    </template>
                </b-table>
              <content-placeholders :rounded="true" v-if="showLoader1">
                <content-placeholders-heading :img="false" />
              </content-placeholders>
            </div>
          </div>
        </div>
      </b-row>
  
      <div
        v-if="associationEvents.length"
        v-observe-visibility="handleScrolledToButtom"
      ></div>
  
      <div
        class="no-products-in-shop"
        v-if="(!associationEvents || associationEvents.length === 0) && showLoader === 0"
      >
        <img src="../../assets/images/no-product.png" alt />
        <p class="header">No association event found.</p>
        <p class="info">
          There are no association event to display in the selected category.
        </p>
        
      </div>
  
      <div
        class="products-list-shim p-0 mt-3"
        v-if="associationEvents.length == 0 && showLoader == 1"
      >
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
        <content-placeholders class="mt-3" :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  let states = require("../../assets/js/state");
  let eventType = require("../../assets/js/eventType");
  import CreateAssociationEventDetailModal from "../Teams/ChatRight/ChatEvents/CreateAssociationEventDetailModal.vue";
  import EditEventDetailModal from "../Teams/ChatRight/ChatEvents/EditEventDetailModal.vue";

export default {
  name: "AssociationEventsDetail",
  components: {
     CreateAssociationEventDetailModal,
     EditEventDetailModal
  },
  data() {
    const today = new Date();
  const currentMonth = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const currentYear = today.getFullYear();
    return {
      selectedItem: null,
      editEventOptionVisible: false,
      events: {},
      associationData: {},
      open: false,
      showLoader: 1,
      association_id: 0,
      showLoader1: 0,
      loading: true,
      fields: [
        {
          key: "startDateForField",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
            const startDate = new Date(item.exactStartDate);
            const endDate = new Date(item.exactEndDate);
            const startDateString = startDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });
            const endDateString = endDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });
            const startYearString = startDate.getFullYear();
            const endYearString = endDate.getFullYear();

            if (startYearString === endYearString) {
              return `${startDateString} to ${endDateString}, ${startYearString}`;
            } else {
              return `${startDateString}, ${startYearString} to ${endDateString}, ${endYearString}`;
            }
          },
        },
        // { key: "association", label: "Association", sortable: true },
        { key: "eventName", label: "Name", sortable: true },
        { key: "director_name", label: "Director", sortable: true },
        { key: "eventType", label: "Type", sortable: true },
        {
          key: "location",
          label: "Location",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return `${item.address || ""}, ${item.city || ""}, ${item.state || ""}`;
          },
          sortBy: (item) => {
            const location =
              (item.address || "") + " " + (item.city || "") + " " + (item.state || "");
            return location.toLowerCase();
          },
        },
        { key: "state", label: "State", sortable: true },
        { key: "action", label: "Action", class: "text-right" },
      ],
      isLoading: false,
      associationEvents: [],
      currentIsAdmin: 0,
      page: 1,
      lastPage: 1,
      total: 0,
      filter: {
        eventType: "",
        associationFilter: "",
        stateFilter: "",
        startDateFilter: `${currentMonth}/${currentYear}`,
      },
      stateOptions: states,
      associationOptions: [],
      eventTypeOptions: eventType,
      filterApplied: false,
      associationGuid: "",
      invalidEventAssociation: false,
      };
    },
    
    computed: {
    filteredEventTypeOptions() {
        return this.eventTypeOptions.filter(option => option.status === 1);
    },
      sortedEvents() {
        const events = this.associationEvents.slice();
        if (this.sortKey === "exactStartDate") {
          events.sort((a, b) => {
            const dateA = new Date(a[this.sortKey]);
            const dateB = new Date(b[this.sortKey]);
  
            if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
              return 0; // handle invalid dates
            }
  
            return this.reverse
              ? dateB.getTime() - dateA.getTime()
              : dateA.getTime() - dateB.getTime();
          });
        } else if (this.sortKey === "location") {
          events.sort((a, b) => {
            const locationA =
              (a.address || "") + " " + (a.city || "") + " " + (a.state || "");
  
            const locationB =
              (b.address || "") + " " + (b.city || "") + " " + (b.state || "");
  
            return this.reverse
              ? locationB.localeCompare(locationA)
              : locationA.localeCompare(locationB);
          });
        } else if (this.sortKey) {
          events.sort((a, b) => {
            const fieldA = a[this.sortKey];
            const fieldB = b[this.sortKey];
  
            if (typeof fieldA === "string" && typeof fieldB === "string") {
              return this.reverse
                ? fieldB.localeCompare(fieldA)
                : fieldA.localeCompare(fieldB);
            } else if (typeof fieldA === "number" && typeof fieldB === "number") {
              return this.reverse ? fieldB - fieldA : fieldA - fieldB;
            } else {
              return 0; 
            }
          });
        }
        return events;
      },
    },

    methods: {
      toggleEditEventOption(item) {
        this.selectedItem = item;
      this.editEventOptionVisible = !this.editEventOptionVisible;
    },
    onClickOutside() {
      this.editEventOption = false;
    },
    editEvent(item){
      this.$root.$emit("updateEditEventDetailModal", item);
    },
    handleRowClick(event) {
      console.log(event);
        this.$root.$emit("eventsIdForDetails", event);
        // this.$router.push({ name: "AssociationSingleEventDetail", params: { id: event.guid } });
    },  
 
    clearEventType() {
      this.filter.eventType = "";
      this.page = 1;
      this.filterApplied = false;
      this.associationEvents = [];
      this.fetchEvents();
    },
    clearState() {
      this.filter.stateFilter = "";
      this.page = 1;
      this.filterApplied = false;
      this.associationEvents = [];
      this.fetchEvents();
    },
    checkDateInput() {
      if (!this.filter.startDateFilter) {
        this.filter.startDateFilter = "";
        this.filterApplied = false;
    }
    this.page = 1;
    this.associationEvents = [];
    this.fetchEvents();
},
    checkEventType() {
      this.associationEvents = [];
      this.page = 1;
      this.fetchEvents();
    },
    handleClick(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.fetchEvents2();
      }
    },

      async fetchEvents() {
        this.showLoader = 1;
        this.showLoader1 = true;
        this.isLoading = true;
        this.page = 1;
        this.associationEvents = [];

        if (!this.associationGuid) {
          const url = window.location.pathname;
          const segment_array = url.split("/");
          this.associationGuid = segment_array.pop();
        }

        let formattedStartDate = "";
        if (this.filter.startDateFilter) {
          const startDateParts = this.filter.startDateFilter.split("/");
          formattedStartDate = `${startDateParts[1]}/${startDateParts[0]}`;
        }

        const formData = new FormData();
        formData.append("associationGuid", this.associationGuid);
        formData.append("event_type", this.filter.eventType);
        formData.append("startDate", formattedStartDate);

        if (this.filter.stateFilter) {
          formData.append("region", this.filter.stateFilter.name);
          formData.append("abbreviation", this.filter.stateFilter.abbreviation);
        }

        // if ((this.filter.eventType || this.filter.startDateFilter || this.filter.stateFilter) &&
        //   !this.filterApplied) {
        //   this.page = 1;
        //   this.associationEvents = [];
        //   this.filterApplied = true;
        // }

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}event/fetchByAssociation?page=${this.page}`,
            formData
          );

          if (response.data.statusCode === 200) {
            this.associationEvents = response.data.events.data;
            // Check if newAssociationData is defined and not empty
            if (this.associationEvents  && this.associationEvents.length > 0) {
              // if (this.page === 1) {
              //   this.associationEvents = [];
              // }

              // this.associationEvents = this.associationEvents.concat(newAssociationData);
              this.associationEvents.sort((a, b) => {
                const dateAStart = new Date(a.exactStartDate);
                const dateBStart = new Date(b.exactStartDate);
                const dateAEnd = new Date(a.exactEndDate);
                const dateBEnd = new Date(b.exactEndDate);

                if (dateAStart.getTime() !== dateBStart.getTime()) {
                  return dateAStart - dateBStart;
                } else {
                  return dateAEnd - dateBEnd;
                }
              });
            } else {
              console.warn("No events data found");
            }

            this.showLoader = 0;
            this.association_id = response.data.association.id;
            this.currentIsAdmin = response.data.currentIsAdmin;
            this.associationData = response.data.association;
            this.$root.$emit("associationTopData", this.associationData, this.showLoader,  this.currentIsAdmin);

            this.lastPage = response.data.events.last_page;
          } else if (response.data.statusCode === 404) {
            this.showLoader = 0;
            this.$alert(response.data.message, 'Error');
            this.$router.push({ name: "Teams" });
          } else if (response.data.statusCode === 400) {
            this.invalidEventAssociation = true;
            this.showLoader = 0;
            this.$root.$emit("invalidAssociationEvents", this.invalidEventAssociation, this.showLoader);
          } else {
            console.error("Error:", response.data.message || "Unknown error occurred");
            this.errorMessage = response.data.message || "Unknown error occurred";
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
          this.errorMessage = "Failed to fetch data";
        } finally {
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoading = false;
        }
      },

      async fetchEvents2() {
        this.showLoader = 1;
        this.showLoader1 = true;
        this.isLoading = true;

        if (!this.associationGuid) {
          const url = window.location.pathname;
          const segment_array = url.split("/");
          this.associationGuid = segment_array.pop();
        }

        let formattedStartDate = "";
        if (this.filter.startDateFilter) {
          const startDateParts = this.filter.startDateFilter.split("/");
          formattedStartDate = `${startDateParts[1]}/${startDateParts[0]}`;
        }

        const formData = new FormData();
        formData.append("associationGuid", this.associationGuid);
        formData.append("event_type", this.filter.eventType);
        formData.append("startDate", formattedStartDate);

        if (this.filter.stateFilter) {
          formData.append("region", this.filter.stateFilter.name);
          formData.append("abbreviation", this.filter.stateFilter.abbreviation);
        }

        // if ((this.filter.eventType || this.filter.startDateFilter || this.filter.stateFilter) &&
        //   !this.filterApplied) {
        //   this.page = 1;
        //   this.associationEvents = [];
        //   this.filterApplied = true;
        // }

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}event/fetchByAssociation?page=${this.page}`,
            formData
          );

          if (response.data.statusCode === 200) {
            const newAssociationData = response.data.events.data;
            // Check if newAssociationData is defined and not empty
            if (newAssociationData && newAssociationData.length > 0) {
              if (this.page === 1) {
                this.associationEvents = [];
              }

              this.associationEvents = this.associationEvents.concat(newAssociationData);
              // this.associationEvents.sort((a, b) => {
              //   const dateAStart = new Date(a.exactStartDate);
              //   const dateBStart = new Date(b.exactStartDate);
              //   const dateAEnd = new Date(a.exactEndDate);
              //   const dateBEnd = new Date(b.exactEndDate);

              //   if (dateAStart.getTime() !== dateBStart.getTime()) {
              //     return dateAStart - dateBStart;
              //   } else {
              //     return dateAEnd - dateBEnd;
              //   }
              // });
            } else {
              console.warn("No events data found");
            }

            this.showLoader = 0;
            this.association_id = response.data.association.id;
            this.currentIsAdmin = response.data.currentIsAdmin;
            this.associationData = response.data.association;
            this.$root.$emit("associationTopData", this.associationData, this.showLoader);

            this.lastPage = response.data.events.last_page;
          } else if (response.data.statusCode === 404) {
            this.showLoader = 0;
            this.$alert(response.data.message, 'Error');
            this.$router.push({ name: "Teams" });
          } else if (response.data.statusCode === 400) {
            this.invalidEventAssociation = true;
            this.showLoader = 0;
            this.$root.$emit("invalidAssociationEvents", this.invalidEventAssociation, this.showLoader);
          } else {
            console.error("Error:", response.data.message || "Unknown error occurred");
            this.errorMessage = response.data.message || "Unknown error occurred";
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
          this.errorMessage = "Failed to fetch data";
        } finally {
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoading = false;
        }
      },
    },
  mounted() {
    this.fetchEvents();
    this.$root.$on("fetchAssociationEvents",()=>{
      this.associationEvents =[];
      this.page=1;
      this.fetchEvents();
    });
    this.$root.$on("associationRoute", (data) => {
      this.associationGuid = data.guid;
      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;
      this.associationEvents = [];
      this.associationData = {},
      this.fetchEvents();
    });
  },
};
</script>
<style scoped>
.table thead th {
  min-width: 150px;
}
.eye-icon {
  width: 30px;
}
.no-game-section {
  height: 150px !important;
}
.no-products-in-shop {
  height: auto !important;
}
.sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.form-group select {
  border: 1px solid #ced4da !important;
}
.clear-icon {
  position: absolute;
  top: 44%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #555;
  font-size: 14px;
}
.event-cancel {
  background-color: #ff5b66;
  color: white;
  border-radius: 50px;
  padding: 0px;
}
.opt {
    height: 30px;
    width: 30px;
}
.sub-setting-options {
    /* top: -177%!important; */
    top: -83%!important;
    right: 24px!important;
}
</style>
