var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar'),_c('div',{staticClass:"setting-main-wrapper"},[_c('div',{staticClass:"main-left-menu"},[_c('LeftMenu')],1),_c('Banner'),_c('div',{staticClass:"shop-side pr-3"},[_c('div',{staticClass:"single-event-page bg-white p-3 mb-3 rounded"},[_c('EventGameTop',{attrs:{"events":_vm.events,"showLoader":_vm.showLoader}}),_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link",class:{
                    active: _vm.currentTab === 'detail',
                    disabled: _vm.isLoaded.detail,
                  },attrs:{"id":"detail-tab","data-toggle":"tab","data-target":"#detail","type":"button","role":"tab","aria-controls":"detail","aria-selected":"true"},on:{"click":function($event){return _vm.selectTab('detail')}}},[_vm._v(" Games ")])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link",class:{
                    active: _vm.currentTab === 'message',
                    disabled: _vm.isLoaded.message,
                  },attrs:{"id":"message-tab","data-toggle":"tab","data-target":"#message","type":"button","role":"tab","aria-controls":"message","aria-selected":"false"},on:{"click":function($event){return _vm.selectTab('message')}}},[_vm._v(" Message Board ")])])]),_c('div',{staticClass:"tab-content mt-4",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade show active",class:{ 'show active': _vm.currentTab === 'detail' },attrs:{"id":"detail","role":"tabpanel","aria-labelledby":"detail-tab"}},[(_vm.currentTab === 'detail')?_c('EventGames',{staticClass:"mt-2",attrs:{"tournamentGame":_vm.tournamentGame,"showLoader":_vm.showLoader}}):_vm._e()],1),_c('div',{staticClass:"tab-pane fade",class:{ 'show active': _vm.currentTab === 'message' },attrs:{"id":"message","role":"tabpanel","aria-labelledby":"message-tab"}},[(_vm.currentTab === 'message')?_c('AsscoaitionMessageBoard',{attrs:{"eventId":_vm.eventId,"messageBodyData":_vm.messageBodyData,"messageTopics":_vm.messageTopics,"showLoaderMessage":_vm.showLoaderMessage,"currentIsAdmin":_vm.currentIsAdmin,"currentIsUmpire":_vm.currentIsUmpire,"association_id":Number(_vm.association_id),"messagepage":_vm.messagepage,"msgLastPage":_vm.msgLastPage}}):_vm._e()],1)])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }