<template>
    <div>
        <Navbar />
        <div class="setting-main-wrapper">
            <div class="main-left-menu">
                <LeftMenu />
            </div>
            <Banner />

            <div class="shop-side p-md-0">
                <LeftMenuSide :openTabSet="openTabSet" />
                <div class="row no-gutters content-wrap-info">
                    <div class="event-section-wrap">
                        <div class="event-section">
                            <h6>Discover Events</h6>
                            <div class="event-section-content">
                                <div class="filter-top">
                                   <DiscoverEventsFilter />
                                </div>
                                <div v-if="eventsData.length > 0">
                                    <div v-for="(event, index) in eventsData" :key="index" class="event-info-wrapper">
                                    <div class="event-info-sec">
                                        <div class="event-sec-top">
                                            <div class="sec-date-name">
                                                <!-- Display event date range using the start and end date -->
                                                <h6>
                                                    {{ formatDateRange(event.exactStartDate, event.exactEndDate) }}
                                                </h6>
                                                <span>{{ event.eventName }}</span>
                                            </div>
                                            <div class="loc-follow">
                                                <div class="loc-content">
                                                    <!-- Show location based on address, city, and state -->
                                                    <img :src="location" alt="location" />
                                                    <span>{{ formatLocation(event) }}</span>
                                                </div>
                                                <div class="follow-unfollow-btns">
                                                    <button class="unfollow-btn">
                                                        <img :src="unfollow" alt="unfollow" />
                                                        <span>Follow</span>
                                                    </button>
                                                    <button class="follow-btn">
                                                        <img :src="follow" alt="follow" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="event-sec-details">
                                            <div class="event-sec-left">
                                                <!-- Conditionally display association and event type -->
                                                <span class="asso-tour">
                                                    {{ event.association ? event.association : '' }}
                                                    {{ event.association && event.eventType ? ' - ' : '' }}
                                                    {{ event.eventType }}
                                                </span>
                                                <span class="director">Director: {{ event.director_name }}</span>
                                            </div>
                                            <div>
                                                
                                            </div>
                                            <div class="event-sec-right">
                                                <template>
                                                        <div class="weather-sec" v-if="event.weatherData">
                                                            <div
                                                            class="weather-content"
                                                            v-for="(dataItem, index) in event.weatherData.slice(0,5)"
                                                            :key="index"
                                                            >
                                                            <div class="weather-date">
                                                                <div class="date-part">{{ formatDateWeather(dataItem.day).date }}</div>
                                                                <div class="year-part">{{ formatDateWeather(dataItem.day).year }}</div>
                                                            </div>
                                                            <img
                                                                :src="iconUrl + dataItem.icon"
                                                                alt="Weather icon"
                                                            />
                                                            <div class="tempr">
                                                                <p class="high">
                                                                {{ dataItem.temperatureMax }}<sup><span class="small-symbol">&deg;F</span></sup>
                                                                </p>
                                                                <p class="low">
                                                                {{ dataItem.temperatureMin }}<sup><span class="small-symbol">&deg;F</span></sup>
                                                                </p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="no-event-wrap" v-if="eventsData.length === 0 && showLoader === 0">
                                    <img src="../../../assets/Svg/calendar-grey.svg" alt />
                                    <p class="header">No discover event found.</p>
                                    <p class="info">
                                        There are no events to display in the discover events.
                                    </p>
                                </div>
                                <div class="products-list-shim mt-3" v-if="eventsData.length == 0 && showLoader == 1">
                                    <content-placeholders :rounded="true">
                                        <content-placeholders-img />
                                        <content-placeholders-heading />
                                    </content-placeholders>
                                    <content-placeholders class="mt-3" :rounded="true">
                                        <content-placeholders-img />
                                        <content-placeholders-heading />
                                    </content-placeholders>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="live-today" v-if="showLiveToday">
                        <LiveToday />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
let states = require("../../../assets/js/state");
// import Multiselect from "vue-multiselect";
import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import DiscoverEventsFilter from "@/components/NewGameTime/Events/DiscoverEventsFilter.vue";
import LiveToday from "@/components/NewGameTime/LiveToday.vue";

export default {
    name: "DiscoverEvents",
    components: {
        Navbar,
        LeftMenu,
        Banner,
        LeftMenuSide,
        DiscoverEventsFilter,
        LiveToday
    },
    data() {
        const today = new Date();
        const currentMonth = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const currentYear = today.getFullYear();
        return {
            iconUrl: "https:",
            openTabSet: 1,
            location: require("../../../assets/Svg/location.svg"),
            unfollow: require("../../../assets/Svg/unfollow.svg"),
            follow: require("../../../assets/Svg/follow.svg"),
            showLiveToday: true,
            stateOptions: states,
            associationOptions: [],
            reverse: false,
            open: false,
            sortKey: "",
            fields: [
                {
                    key: "startDateForField",
                    label: "Date",
                    sortable: true,
                    formatter: (value, key, item) => {
                        const startDate = new Date(item.exactStartDate);
                        const endDate = new Date(item.exactEndDate);

                        const startDateString = startDate.toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                        });
                        const endDateString = endDate.toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                        });
                        const startYearString = startDate.getFullYear();
                        const endYearString = endDate.getFullYear();

                        if (startYearString === endYearString) {
                            return `${startDateString} to ${endDateString}, ${startYearString}`;
                        } else {
                            return `${startDateString}, ${startYearString} to ${endDateString}, ${endYearString}`;
                        }
                    },
                    sortBy: (item) => {
                        // Custom sorting function based on startDateForField
                        return new Date(item.startDateForField).getTime(); // Sort by timestamp
                    },
                },
                { key: "association", label: "Association", sortable: true },
                { key: "eventName", label: "Name", sortable: true },
                { key: "director_name", label: "Director", sortable: true },
                { key: "eventType", label: "Type", sortable: true },
                {
                    key: "location",
                    label: "Location",
                    sortable: true,
                    sortByFormatted: true,
                    formatter: (value, key, item) => {
                        return `${item.address || ""}, ${item.city || ""}, ${item.state || ""
                            }`;
                    },
                    sortBy: (item) => {
                        const location =
                            (item.address || "") +
                            " " +
                            (item.city || "") +
                            " " +
                            (item.state || "");
                        return location.toLowerCase();
                    },
                },
                { key: "state", label: "State", sortable: true },
            ],

            filter: {
                name: "",
                startDate: `${currentMonth}/${currentYear}`, // Set default to current month/year
                association: "",
                region: [],
            },
            eventsAll: [],
            messages: [],
            page: 1,
            lastPage: 1,
            eventsData: [],
            showLoader: 0,
            showLoader1: false,
        };
    },
    computed: {
        contentColSize() {
            return this.showLiveToday ? 8 : 10;
        }
    },
    methods: {
        formatDateWeather(date) {
        const options = { month: 'short', day: 'numeric' };
        const datePart = new Date(date).toLocaleDateString(undefined, options);
        const yearPart = new Date(date).getFullYear();
        return { date: datePart, year: yearPart };
        },
        formatDateRange(startDate, endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);

            const startDateString = start.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            });
            const endDateString = end.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            });
            const startYear = start.getFullYear();
            const endYear = end.getFullYear();

            if (startYear === endYear) {
            return `${startDateString} to ${endDateString}, ${startYear}`;
            } else {
            return `${startDateString}, ${startYear} to ${endDateString}, ${endYear}`;
            }
        },

        // Format the location string using address, city, and state
        formatLocation(event) {
            let location = '';

            if (event.address) {
                location += event.address;
            }

            if (event.city) {
                // Add comma if address exists, else just city
                location += (location ? ', ' : '') + event.city;
            }

            if (event.state) {
                // Add comma if address or city exists, else just state
                location += (location ? ', ' : '') + event.state;
            }

            return location;
            },
        handleClear() {
            this.open = false; // Close the date picker
        },
        redirectToEvent(item) {
            const eventId = item.guid;
            this.$router.push({ name: 'AssociationSingleEventDetail', params: { id: eventId } });
        },
        handleClick(evt) {
            if (evt.target.tagName !== "INPUT") {
                this.open = true;
            }
        },
        ccustomDateSortCompare(a, b, sortBy, sortDesc) {
            // Check if the sortBy field is a date field
            if (sortBy === "exactStartDate") {
                const dateA = new Date(a[sortBy]);
                const dateB = new Date(b[sortBy]);

                // Handle invalid dates
                if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                    return 0;
                }

                // Compare dates
                return sortDesc
                    ? dateB.getTime() - dateA.getTime()
                    : dateA.getTime() - dateB.getTime();
            } else {
                // For non-date fields, fallback to default sorting behavior
                return 0;
            }
        },
        showLocation(event) {
            return (
                (event.status === 1 &&
                    event.address !== null &&
                    event.address !== "" &&
                    event.address !== "null") ||
                (event.city !== null && event.city !== "" && event.city !== "null") ||
                (event.state !== null && event.state !== "" && event.state !== "null")
            );
        },
        getLocation(event) {
            const address =
                event.address && event.address !== "null" ? event.address : "";
            const city = event.city && event.city !== "null" ? event.city : "";
            const state = event.state && event.state !== "null" ? event.state : "";

            return `${address} ${city}, ${state}`.trim();
        },
        remove(option) {
            // Find the index of the option in the array
            const index = this.filter.association.findIndex(
                (item) => item.association_name === option.association_name
            );

            // Remove the item if it exists in the array
            if (index !== -1) {
                this.filter.association.splice(index, 1);
            }
            this.getFilters();
        },
        async getFilters() {
            console.log("msdm")
            this.page = 1;
            this.showLoader = 1;
            this.showLoader1 = true;
            this.eventsData = [];
            this.isLoading = true;

            let formattedStartDate = "";
            if (this.filter.startDate) {
                // Split the month/year format
                const startDateParts = this.filter.startDate.split("/");

                // Create a new date object representing the first day of the selected month and year
                formattedStartDate = `${startDateParts[1]}/${startDateParts[0]}`; // Format as MM/DD/YYYY
            }

            try {
                let selectedRegions = [];
                let selectedAssociations = [];

                // Populate selectedRegions if this.filter.region exists
                if (this.filter.region) {
                    selectedRegions = this.filter.region.map((r) => ({
                        name: r.name,
                        abbreviation: r.abbreviation,
                    }));
                }

                // Populate selectedAssociations if this.filter.association exists
                if (this.filter.association) {
                    selectedAssociations = this.filter.association.map((a) => ({
                        name: a.association_name,
                    }));
                }

                // Make the API call with defined variables
                const filteredEvents = await axios.post(
                    `${process.env.VUE_APP_API_URL}event/adminCreatedEventListing?page=${this.page}`,
                    {
                        name: this.filter.name,
                        startDate: formattedStartDate,
                        age: this.filter.age, // Include age filter
                        association: selectedAssociations, // Include association filter
                        regions: selectedRegions, // Send name and abbreviation for each region
                    }
                );

                if (filteredEvents.data.statusCode === 200) {
                    const newEventData = filteredEvents.data.data.data;
                    if (this.page === 1) {
                        this.eventsData = [];
                    }
                    this.eventsData = this.eventsData.concat(newEventData);
                    this.eventsData.sort((a, b) => {
                        const dateAStart = new Date(a.exactStartDate);
                        const dateBStart = new Date(b.exactStartDate);
                        const dateAEnd = new Date(a.exactEndDate);
                        const dateBEnd = new Date(b.exactEndDate);

                        // Compare start dates first
                        if (dateAStart.getTime() !== dateBStart.getTime()) {
                            return dateAStart - dateBStart;
                        } else {
                            // If start dates are the same, compare end dates
                            return dateAEnd - dateBEnd;
                        }
                    });

                    // this.eventsData = filteredEvents.data.data.data;
                    this.lastPage = filteredEvents.data.data.last_page;
                    this.showLoader = 0;
                    this.showLoader1 = false;
                    this.isLoading = false;
                } else {
                    this.errorMessage = filteredEvents.data.message;
                    this.showLoader = 0;
                    this.showLoader1 = false;
                    this.isLoading = false;
                }
            } catch (error) {
                this.errorMessage = "Failed to fetch data";
                this.showLoader = 0;
                this.showLoader1 = false;
            }
        },
        openGoogleMap(item) {
            const strlatLng = item["lan"] + "," + item["long"];
            const urlSuffix = strlatLng
                ? strlatLng
                : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

            window.open(
                "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
                "_blank"
            );
        },

        handleRowClick(event) {
            this.$root.$emit("eventsIdForDetails", event);

            // setTimeout(() => {
            //   this.$root.$emit("eventsIdForDetails", event);
            //   this.$router.push({ name: "AssociationSingleEventDetail", params: { id: event.guid } });
            // }, 500);
        },

        handleScrolledToButtom(isVisible) {
            if (this.page >= this.lastPage) {
                return;
            }
            if (isVisible) {
                this.page++;
                this.EventsData();
            }
        },

        async EventsData() {
            this.showLoader = 1;
            this.showLoader1 = true;
            let formattedStartDate = "";
            if (this.filter.startDate) {
                // Split the month/year format
                const startDateParts = this.filter.startDate.split("/");

                // Create a new date object representing the first day of the selected month and year
                formattedStartDate = `${startDateParts[1]}/${startDateParts[0]}`; // Format as MM/DD/YYYY
            }
            try {

                const selectedRegions = this.filter.region.map((r) => r.name);
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}event/adminCreatedEventListing?page=${this.page}`,
                    {
                        name: this.filter.name,
                        startDate: formattedStartDate,
                        age: this.filter.age, // Include age filter
                        association: this.filter.association, // Include association filter
                        regions: selectedRegions,
                        abbreviation: this.filter.region.abbreviation,
                    }
                );

                if (response.data.statusCode === 200) {
                    this.eventsData.push(...response.data.data.data);
                    this.eventsData.sort((a, b) => {
                        const dateA = new Date(a.exactStartDate);
                        const dateB = new Date(b.exactStartDate);
                        return dateA - dateB;
                    });
                    this.lastPage = response.data.data.last_page;
                    this.showLoader = 0;
                    this.showLoader1 = false;
                } else {
                    this.errorMessage = response.data.message;
                    this.showLoader = 0;
                    this.showLoader1 = false;
                }
            } catch (error) {
                this.errorMessage = "Failed to fetch data";
                this.showLoader = 0;
                this.showLoader1 = false;
            } finally {
                this.showLoader = 0;
                this.showLoader1 = false;
            }
        },
        checkDateInput() {
            if (!this.filter.startDate) {
                this.filter.startDate = "";
                this.getFilters();
            }
        },
        clearName() {
            this.filter.name = "";
            this.getFilters();
        },
        clearAssociation() {
            this.filter.association = [];
            this.getFilters();
        },
        clearState() {
            this.filter.region = [];
            this.getFilters();
        },
        getAssociation() {
            axios
                .get(
                    `${process.env.VUE_APP_API_URL}getAssociations?search=${""}`,
                    this.details,
                    {}
                )
                .then((response) => {
                    this.associationOptions = [];
                    this.associationOptions = response.data.data.associations;

                });
        },
    },
    mounted() {
        this.getAssociation();
        this.EventsData();
        this.$root.$on("openTabSetting", (id) => {
            this.openTabSet = id;
        });
    },
}
</script>