<template>
    <div class="live-section">
        <div class="live-top">
            <h6>Live Today</h6>
            <button>See All</button>
        </div>
        <div class="live-info">
            <div class="info-sec-content">
                <div class="live-inner-top">
                    <div class="live-inner-left">
                        <span class="live-delayed">Live</span>
                        <img :src="video" alt="video" />
                        <span class="pool-text">Pool 1</span>
                    </div>
                    <div class=live-count>3</div>
                </div>
                <div class="live-center-info">
                    <span class="live-time">Started at 12:30 PM</span>
                    <div class="live-teams">
                        <div class="live-teams-info">
                            <div class="team-info-sec">
                                <img :src="team_image" alt="team-image" />
                                <span class="name-info">#1: Vegas Boyz</span>
                            </div>
                            <div class="team-info-sec">
                                <img :src="team_image" alt="team-image" />
                                <span class="name-info">#4: Dudley 60</span>
                            </div>
                        </div>
                        <div class="live-teams-scores">
                            <div class="score-info-sec">
                                <span>0</span>
                                <span>0</span>
                            </div>
                            <img :src="arrow_left" alt="arrow-left" />
                        </div>
                    </div>
                    <div>
                        <p>Men’s 65/70+ Silver Division  - The Proton Stadium</p>
                        <div class="live-ground-sec">
                            <img :src="ground" alt="ground" />
                            <p>Homefield Baseball Complex - Field 1</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info-sec-content">
                <div class="live-inner-top">
                    <div class="live-inner-left">
                        <span class="live-delayed">Live</span>
                        <img :src="video" alt="video" />
                        <span class="pool-text">Pool 1</span>
                    </div>
                    <div class=live-count>3</div>
                </div>
                <div class="live-center-info">
                    <span class="live-time">Started at 12:30 PM</span>
                    <div class="live-teams">
                        <div class="live-teams-info">
                            <div class="team-info-sec">
                                <img :src="team_image" alt="team-image" />
                                <span class="name-info">#1: Vegas Boyz</span>
                            </div>
                            <div class="team-info-sec">
                                <img :src="team_image" alt="team-image" />
                                <span class="name-info">#4: Dudley 60</span>
                            </div>
                        </div>
                        <div class="live-teams-scores">
                            <div class="score-info-sec">
                                <span>0</span>
                                <span>0</span>
                            </div>
                            <img :src="arrow_left" alt="arrow-left" />
                        </div>
                    </div>
                    <div>
                        <p>Men’s 65/70+ Silver Division  - The Proton Stadium</p>
                        <div class="live-ground-sec">
                            <img :src="ground" alt="ground" />
                            <p>Homefield Baseball Complex - Field 1</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info-sec-content">
                <div class="live-inner-top">
                    <div class="live-inner-left">
                        <span class="live-delayed">Live</span>
                        <img :src="video" alt="video" />
                        <span class="pool-text">Pool 1</span>
                    </div>
                    <div class=live-count>3</div>
                </div>
                <div class="live-center-info">
                    <span class="live-time">Started at 12:30 PM</span>
                    <div class="live-teams">
                        <div class="live-teams-info">
                            <div class="team-info-sec">
                                <img :src="team_image" alt="team-image" />
                                <span class="name-info">#1: Vegas Boyz</span>
                            </div>
                            <div class="team-info-sec">
                                <img :src="team_image" alt="team-image" />
                                <span class="name-info">#4: Dudley 60</span>
                            </div>
                        </div>
                        <div class="live-teams-scores">
                            <div class="score-info-sec">
                                <span>0</span>
                                <span>0</span>
                            </div>
                            <img :src="arrow_left" alt="arrow-left" />
                        </div>
                    </div>
                    <div>
                        <p>Men’s 65/70+ Silver Division  - The Proton Stadium</p>
                        <div class="live-ground-sec">
                            <img :src="ground" alt="ground" />
                            <p>Homefield Baseball Complex - Field 1</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'LiveToday',
    data() {
        return {
            video: require("../../assets/Svg/video.svg"),
            team_image: require("../../assets/Svg/people.svg"),
            arrow_left: require("../../assets/Svg/arrow-left.svg"),
            ground: require("../../assets/Svg/ground.svg"),
        }
    }
  }
</script>