<template>
    <div>
        <Navbar />
        <div class="setting-main-wrapper">
            <div class="main-left-menu">
                <LeftMenu />
            </div>
            <Banner />

            <div class="shop-side p-md-0">
                <LeftMenuSide :openTabSet="openTabSet" />
                <div class="row no-gutters content-wrap-info">
                    <div class="event-section-wrap">
                        <div class="event-section">
                           <h6>Events I Follow</h6>
                            <div class="event-section-content">
                                <div class="filter-top">
                                    <FollowingEventsFilter />
                                </div>
                                <div class="event-info-wrapper">
                                    <div class="event-info-sec">
                                        <div class="event-sec-top">
                                            <div class="sec-date-name">
                                                <h6>Mar 8 to Mar 10, 2024 (Eastern Time)</h6>
                                                <span>Bart Adams March Madness II</span>
                                            </div>
                                            <div class="loc-follow">
                                                <div class="loc-content">
                                                    <img :src="location" alt="location" />
                                                    <span>Bullhead City, AZ</span>
                                                </div>
                                                <div class="follow-unfollow-btns">
                                                    <button class="unfollow-btn">
                                                        <img :src="unfollow" alt="unfollow" />
                                                        <span>Follow</span>
                                                    </button>
                                                    <button class="follow-btn">
                                                        <img :src="follow" alt="follow" /> 
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="event-sec-details">
                                            <div class="event-sec-left">
                                                <span class="asso-tour">SUSSA - Tournament</span>
                                                <span class="director">Director: TomWhitesides</span>
                                            </div>
                                            <div class="event-sec-right"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="live-today" v-if="showLiveToday">
                        <LiveToday />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import FollowingEventsFilter from "@/components/NewGameTime/Events/FollowingEventsFilter.vue";
import LiveToday from "@/components/NewGameTime/LiveToday.vue";

export default {
    name: "FollowingEvents",
    components: {
        Navbar,
        LeftMenu,
        Banner,
        LeftMenuSide,
        FollowingEventsFilter,
        LiveToday
    },
    data() {
        return {
            openTabSet: 1,
            location: require("../../../assets/Svg/location.svg"),
            unfollow: require("../../../assets/Svg/unfollow.svg"),
            follow: require("../../../assets/Svg/follow.svg"),
            showLiveToday: true,   
        };
    },
    mounted() {
        this.$root.$on("openTabSetting", (id) => {
            this.openTabSet = id;
        });
    },
};
</script>