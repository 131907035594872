<template>
  <div>
    <TournamentGameListModal />
    <TournamentEditGameModal />
    <StartGameModal />
    <DelayedGameModal />
    <div class="d-md-flex align-items-center justify-content-between">
      <div class="score-game-name" @click="openGameModal">
        <span>Switch Game</span>
        <h6 class="text-capitalize">{{ singleGame?.game_name }}</h6>
      </div>
      <h6 class="event-game-name text-capitalize text-left text-md-right">
        <router-link
          :to="{
            name: 'AssociationSingleEventDetail',
            params: { id: singleGame?.event?.guid },
          }"
          class="stats-link"
        >
          {{ singleGame?.event?.eventName }}
        </router-link>
        -
        <router-link
          :to="{
            name: 'Tournament',
            params: { id: singleGame?.tournament?.guid },
          }"
          class="stats-link"
        >
          {{ singleGame?.tournament?.tournamentName }}
        </router-link>
      </h6>
    </div>
    <div class="d-md-flex align-items-center justify-content-between mt-3">
      <div class="game-umpire-data">
        <p class="mb-2 cursor-pointer" @click="openParkGoogleMap(singleGame.park)">
            {{ singleGame?.park?.park_name }}
            {{ singleGame?.field ? "- " + singleGame?.field?.field_name : "" }}
        </p>
        <p class="umpire-name text-capitalize">
          Umpire
          <span v-if="singleGame?.umpire?.name">
            ({{ singleGame?.umpire?.name }})
          </span>
          <span v-else>(Not Assigned)</span>
        </p>
      </div>

      
      <div v-if="teamOneData && teamTwoData">
        #{{ teamOneData.teamSrNo }} {{ teamOneData.teamName }} VS #{{ teamTwoData.teamSrNo }} {{ teamTwoData.teamName }}
      </div>
    </div>
    <div class="d-md-flex align-items-center justify-content-between">
      <div>
        <div class="event-date">
          <img src="../../../assets/images/calendar-event.png" />
          <div class="content">
            Scheduled:
            {{ singleGame?.start_date | formatDateOfBirth }}
            {{ singleGame?.start_time }}- Actual Start Time
            {{ singleGame?.actual_start_time }} - Time Limit:
            {{ singleGame?.time_limit }} Minutes
          </div>
        </div>
        <div
          class="location-content"
          v-if="
            (singleGame?.event?.status == 1 &&
              singleGame?.event?.location != null &&
              singleGame?.event?.location != '' &&
              singleGame?.event?.location != 'null') ||
              (singleGame?.event?.city != null &&
                singleGame?.event?.city != '' &&
                singleGame?.event?.address != 'null') ||
              (singleGame?.event?.state != null &&
                singleGame?.event?.state != '' &&
                singleGame?.event?.state != 'null')
          "
        >
          <img
            src="../../../assets/images/location.png"
            @click="openGoogleMap(singleGame.event)"
          />
          <span class="content" @click="openGoogleMap(singleGame.event)"
            >{{ singleGame?.event?.location }} {{ singleGame?.event?.city }},
            {{ singleGame?.event?.state }}</span
          >
        </div>
        <div class="location-content" v-if="singleGame?.event?.status == 2">
          <img src="../../../assets/images/link.png" />
          <a class="content" :href="singleGame.event.Url" target="_blank">
            {{ singleGame?.event.Url }}
          </a>
        </div>
      </div>

      <div class="d-flex flex-column" >
        <div class="d-flex align-items-center justify-content-end gap-8">
          <button class="btn btn-warning"
            v-if="this.singleGame?.status == 2 && !this.singleGame?.locked && (currentIsAdmin === '1' || currentIsUmpire === '1')"
            @click="restartGame">
        Reopen Game
       </button>
        <button
            class="btn btn-danger"
            v-if="this.singleGame?.status == 2 && !this.singleGame?.locked && currentIsAdmin === '1'"
            @click="lockGame"
        >
            Lock Game
        </button>
        <button
            class="btn btn-danger"
            v-if="this.singleGame?.status == 2 && currentIsAdmin === '1' && this.singleGame?.locked"
            @click="unlockGame"
        >
            Unlock Game
        </button>
        <button
          class="btn btn-warning"
          v-if="this.scores.length == 0 && (currentIsAdmin === '1' || currentIsUmpire === '1')"
          @click="callStartGameModal"
        >
          Start Game
        </button>
        
        <button
          class="btn btn-warning"
          v-if="this.scores.length != 0 && this.singleGame.game_delayed != 1 && this.singleGame?.status != 2 && (this.singleGame.scoring_user_id === null || String(this.singleGame.scoring_user_id) === String(this.user_id))  && (currentIsAdmin === '1' || currentIsUmpire === '1')"
          @click="delayedGameModal(singleGame)">
          Game Delayed
        </button>
        <button
          class="btn btn-warning"
          v-if="this.scores.length != 0 && this.singleGame.game_delayed == 1 && this.singleGame?.status != 2 && (this.singleGame.scoring_user_id === null || String(this.singleGame.scoring_user_id) === String(this.user_id))  && (currentIsAdmin === '1' || currentIsUmpire === '1')"
          @click="resumeGame(singleGame)">
          Resume Game
        </button>
        <div class="opt edit-btn mt-3" v-if="currentIsAdmin == '1'">
          <img
            src="../../../assets/images/ellipsis-h.png"
            alt=""
            @click="openGameOption"
            v-click-outside="onClickOutside"
          />
          <div class="sub-setting-options edit-event-options"  v-if="gameOption && currentIsAdmin == '1'">
            <ul class="d-block pb-0 text-left">
              <!-- <li @click="callModal(singleGame)" class="cursor-pointer">Edit Game</li> -->
              <li @click="deleteGame(singleGame)" class="cursor-pointer">Delete Game</li>
              <li @click="editGameModal(singleGame)" class="cursor-pointer">Edit Game</li>
            </ul>
          </div>
        </div>
        </div>

        <div v-if="singleGame.game_delayed != 1 && singleGame?.game_live == 1 ">
          <div class="remain-time" v-if="singleGame.game_overed === 0">
            Remaining time: {{ formattedRemainingTime }}
          </div>
          <div class="remain-time text-danger" v-else>
            Total overtime: {{ formattedOverTime }}
          </div>
        </div>

      </div>
        
     
    </div>
  </div>
</template>

<script>
import DelayedGameModal from "../Modals/DelayedGameModal.vue";
import StartGameModal from "../Modals/StartGameModal.vue";
import TournamentEditGameModal from "../Modals/TournamentEditGameModal.vue";
import TournamentGameListModal from "./TournamentGameListModal.vue";
import axios from "axios";

export default {
  components: {
    TournamentEditGameModal,
    StartGameModal,
    TournamentGameListModal,
    DelayedGameModal,
  },
  name: "ScoreTop",
  data() {
    return {
      timer: null,
      gameOption: false,
      teamOneData: null,
      teamTwoData: null,
      user_id : localStorage.getItem("userId"),
    };
  },
  props: {
    singleGame: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentIsUmpire: {
      type: String,
    },
    scores: {
      type: Array,
    },
    association_id: {
      type: Number,
    },
  },
  computed: {
    formattedRemainingTime() {
      return this.singleGame.remaining_time;
    },
    formattedOverTime() {
      return this.singleGame.total_over_time;
    },
  },
  created() {
    this.extractTeamData();
  },
  methods: {
    extractTeamData() {
      if (
        this.singleGame &&
        this.singleGame.game_scores &&
        this.singleGame.game_scores.length > 0
      ) {
        const gameScores = this.singleGame.game_scores;
        const teamOneId = this.singleGame.team_1_id;
        const teamTwoId = this.singleGame.team_2_id;

        // Find team data for team_one
        const teamOneScore = gameScores.find(
          (score) => score.team_id === teamOneId
        );
        if (teamOneScore) {
          const teamOneData =
            teamOneScore.team_type === 1
              ? this.singleGame.team_one
              : this.singleGame.team_two;
          this.teamOneData = {
            teamName: teamOneData.team_data.team_name,
            teamSrNo: teamOneData.team_sr_no,
          };
        }

        // Find team data for team_two
        const teamTwoScore = gameScores.find(
          (score) => score.team_id === teamTwoId
        );
        if (teamTwoScore) {
          const teamTwoData =
            teamTwoScore.team_type === 1
              ? this.singleGame.team_one
              : this.singleGame.team_two;
          this.teamTwoData = {
            teamName: teamTwoData.team_data.team_name,
            teamSrNo: teamTwoData.team_sr_no,
          };
        }
      }
    },
    openGameOption() {
      this.gameOption = !this.gameOption;
    },
    onClickOutside() {
      this.gameOption = false;
    },

    editGameModal(singleGame) {
      this.$root.$emit("openTournamentEditGameModal2", singleGame);
    },

    async deleteGame(singleGame) {
      let confirmationMessage = `About to delete game ${singleGame.game_name}. Continue ?`;

      if (singleGame.team_one && singleGame.team_two &&
          singleGame.team_one.team_data && singleGame.team_two.team_data &&
          singleGame.team_one.team_data.team_name && singleGame.team_two.team_data.team_name) {
        confirmationMessage = `About to delete game ${singleGame.game_name} (${singleGame.team_one.team_data.team_name} vs ${singleGame.team_two.team_data.team_name}). Continue ?`;
      }

      this.$confirm(confirmationMessage).then(() => {
        var url = window.location.pathname;
        var segment_array = url.split("/");
        const Guid = segment_array.pop();
        var formData = new FormData();
        formData.append("game_id", singleGame.id);
        formData.append("tournament_guid", Guid);
        formData.append("association_id", this.association_id);
        let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,

        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });

        axios
          .post(process.env.VUE_APP_API_URL + "tournaments/deleteTournamentGame", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            loader.hide();
            if (response.data.statusCode === 200) {
              const tournamentId = this.singleGame.tournament.guid;
              this.$router.push({ name: "Tournament", params: { id: tournamentId } });
              loader.hide();
            } else if (response.data.statusCode === 403) {
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
              loader.hide();
            } else {
            this.$alert(response.data.message, 'Delete game').then(() => {
              const tournamentId = this.singleGame.tournament.guid;
              this.$router.push({ name: "Tournament", params: { id: tournamentId } });
              loader.hide();
            });
          }
          })
          .catch(error => {
            console.error("An error occurred while deleting the game:", error);     
            loader.hide();       
          });
      });
    },
    
    openParkGoogleMap(item) {
        let urlSuffix;
        if (item["lat"] && item["lng"]) {
            const strlatLng = item["lat"] + "," + item["lng"];
            urlSuffix = strlatLng;
        } else {
            urlSuffix = encodeURIComponent(item["address"] + ", " + item["city"] + ", " + item["zipCode"]);
        }
        window.open(
            "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
            "_blank"
        );
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    callStartGameModal: function() {   
          this.$root.$emit("openStartGameTournamentModal", this.singleGame);
      },
    delayedGameModal(singleGame) {
      this.$root.$emit("openDelayedGameTournamentModal", singleGame);
    },
    restartGame() {
      this.$root.$emit("restart-game");
    },
    openGameModal: function() {
      this.$root.$emit("openTournamentGamesDataModal", this.singleGame);
    },
    resumeGame: function () {
      this.$confirm(
        "About to resume the game, Continue?",
        "Resume Game."
      ).then(() => {
        this.gameActionLoader = 1;
        var formData = new FormData();
        formData.append("tournament_game_id", this.singleGame.id);
        axios
          .post(
            process.env.VUE_APP_API_URL + "tournaments/game/gameResumeAfterDelay",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 300) {
              alert(response.data.message);
            } else if (response.data.statusCode == 404) {
              this.$alert(response.data.message, "Error");
              this.$router.push({ name: "Teams" });
            } else if (response.data.statusCode == 500) {
              alert(response.data.message);
            } else {
              let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,

                color: "#ffffff",
                backgroundColor: "#333",
                opacity: 0.6,
              });

              loader.hide();
              this.$root.$emit("showTounamentGameScore");
            }
          });
      });
    },
    lockGame: function () {
  this.$confirm(
    "About to lock the game which will restrict score modification for the game. Admin can unlock it again at any time to modify the scores. Continue ?",
    "Lock Game."
  ).then(() => {
    this.gameActionLoader = 1;
    var formData = new FormData();
    formData.append("tournament_game_id", this.singleGame.id);
    axios
      .post(
        process.env.VUE_APP_API_URL + "tournaments/game/lockGame",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode == 404) {
          // this.$alert("Delayed game can not be locked").then(() => {
            this.$alert(response.data.message, "Error");
            this.$router.push({ name: "Teams" }); 
           //  });
        } else if (response.data.statusCode == 300) {
          alert(response.data.message);
        } else if (response.data.statusCode == 500) {
          alert(response.data.message);
        } else {
          let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.formContainer,
            color: "#ffffff",
            backgroundColor: "#333",
            opacity: 0.6,
          });
          loader.hide();
          this.$root.$emit("showTounamentGameScore");
        }
      });
  });
},

    unlockGame: function () {
      this.$confirm(
        "About to unlock the game which will allow to modify the scores. Continue ?",
        "Unlock Game."
      ).then(() => {
        this.gameActionLoader = 1;
        var formData = new FormData();
        formData.append("tournament_game_id", this.singleGame.id);
        axios
          .post(
            process.env.VUE_APP_API_URL + "tournaments/game/unlockGame",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 300) {
              alert(response.data.message);
            } else if (response.data.statusCode == 404) {
              this.$alert(response.data.message, "Error");
              this.$router.push({ name: "Teams" });
            } else if (response.data.statusCode == 500) {
              alert(response.data.message);
            } else {
              let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,

                color: "#ffffff",
                backgroundColor: "#333",
                opacity: 0.6,
              });
              // this.getScores();

              loader.hide();
              // this.gameActionLoader = 0;
              this.$root.$emit("showTounamentGameScore");
            }
          });
      });
    },
    startTimer() {
      if (this.singleGame.status === 1) {
        this.timer = setInterval(() => {
          if (this.singleGame.game_overed === 0) {
            this.decrementTime();
          } else {
            this.incrementOverTime();
          }
        }, 1000);
      }
    },
    decrementTime() {
      const timeParts = this.singleGame.remaining_time.split(":").map(Number);
      let [hours, minutes, seconds] = timeParts;

      if (hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(this.timer);
        this.singleGame.game_overed = 1;
        this.$root.$emit("showTounamentGameScore");
        return;
      }

      seconds--;
      if (seconds < 0) {
        seconds = 59;
        minutes--;
        if (minutes < 0) {
          minutes = 59;
          hours--;
        }
      }

      this.singleGame.remaining_time = this.formatTime(hours, minutes, seconds);
    },
    incrementOverTime() {
      const timeParts = this.singleGame.total_over_time.split(":").map(Number);
      let [hours, minutes, seconds] = timeParts;

      seconds++;
      if (seconds === 60) {
        seconds = 0;
        minutes++;
        if (minutes === 60) {
          minutes = 0;
          hours++;
        }
      }

      this.singleGame.total_over_time = this.formatTime(hours, minutes, seconds);
    },
    formatTime(hours, minutes, seconds) {
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style></style>
