var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.events && _vm.showLoader == 0)?_c('div',{staticClass:"sec-top d-flex justify-content-between"},[_c('div',{staticClass:"left-part"},[_c('h4',[_vm._v(_vm._s(_vm.events.eventName))]),_c('p',{staticClass:"asso-type"},[_vm._v(" "+_vm._s(_vm.events.association)+" - "+_vm._s(_vm.events.eventType)+" ")]),_c('div',{staticClass:"event-list-details event-info-wrap"},[_c('div',{staticClass:"event-date"},[_c('img',{attrs:{"src":require("../../assets/images/calendar-event.png")}}),(_vm.events && _vm.events.exactStartDate && _vm.events.exactEndDate)?_c('div',{staticClass:"content"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.events.exactStartDate))+" - "+_vm._s(_vm.formatDate(_vm.events.exactEndDate))+" ")])]):_vm._e()]),(
                (_vm.events.status == 1 &&
                 _vm.events.address != null &&
                 _vm.events.address != '' &&
                 _vm.events.address != 'null') ||
                  (_vm.events.city != null &&
                   _vm.events.city != '' &&
                   _vm.events.address != 'null') ||
                  (_vm.events.state != null &&
                   _vm.events.state != '' &&
                   _vm.events.state != 'null')
              )?_c('p',{staticClass:"location-content"},[_c('img',{attrs:{"src":require("../../assets/images/location.png")},on:{"click":function($event){return _vm.openGoogleMap(_vm.events)}}}),_c('span',{staticClass:"content",on:{"click":function($event){return _vm.openGoogleMap(_vm.events)}}},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.events.address)+" "+_vm._s(_vm.events.city)+", "+_vm._s(_vm.events.state))])])]):_vm._e()])]),_c('div',{staticClass:"right-part asso-right-part"},[(_vm.events.director_name)?_c('p',{staticClass:"director"},[_vm._v("Director : "+_vm._s(_vm.events.director_name))]):_c('p',{staticClass:"director"},[_vm._v("Director : N/A")]),(_vm.events.director_phone)?_c('p',{staticClass:"num"},[_vm._v(" "+_vm._s(_vm.events.mob_code)+" "+_vm._s(_vm.events.director_phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')))]):_c('p',{staticClass:"num"},[_vm._v(" N/A")]),(_vm.events.director_email)?_c('p',{staticClass:"email"},[_vm._v(_vm._s(_vm.events.director_email))]):_c('p',{staticClass:"email"},[_vm._v("N/A")])])]):_vm._e(),(_vm.showLoader == 1)?_c('div',{staticClass:"products-list-shim p-0"},[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-img'),_c('content-placeholders-heading')],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }