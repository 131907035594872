<template>
    <div>
        <Navbar />
        <div class="setting-main-wrapper">
            <div class="main-left-menu">
                <LeftMenu />
            </div>
            <Banner />

            <div class="shop-side p-md-0">
                <LeftMenuSide :openTabSet="openTabSet" />
                <div class="row no-gutters content-wrap-info">
                    <div class="event-section-wrap team-section-wrap">
                        <div class="event-section">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6>My Teams</h6>
                                <div class="add-new-team-btn">
                                    <div  class="form-inline bg-white">
                                        <button>
                                            <img class="search-image" :src="search" alt="search" />
                                            </button>
                                            <input v-model="searchTerm" aria-label="Search" class="form-control border-0 bg-white" placeholder="Search"
                                            type="search" v-on:keyup.enter="searchValue" />
                                        </div>
                                        <button>
                                            <img :src="add" alt="add-team" />
                                            <span>Add Team</span>
                                        </button>
                                    </div>
                                </div>
                            <div class="event-section-content">
                                <div class="filter-top">
                                    <MyTeamsFilter />
                                </div>
                                <div class="event-info-wrapper">
                                    <div class="event-info-sec">
                                        <div class="event-sec-top team-sec-top">
                                            <div class="team-sec-left">
                                                <div class="team-info-image">
                                                    <img :src="teams" alt="team" />
                                                </div>
                                                <div class="sec-date-name">
                                                    <h6>Okay City Mayhem</h6>
                                                    <span>65 Older - AAA - Male</span>
                                                </div>
                                            </div>
                                            <div class="team-mates-wrap">
                                                <div class="team-mates-info">
                                                    <span>16 Teammates</span>
                                                </div>
                                                <div class="event-sec-right">
                                                    <div class="game-wrapper">
                                                        <div class="total-game">
                                                            <span>G</span>
                                                            <span>0</span>
                                                        </div>
                                                        <div class="win-game">
                                                            <span>W</span>
                                                            <span>0</span>
                                                        </div>
                                                        <div class="loss-game">
                                                            <span>L</span>
                                                            <span>0</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import MyTeamsFilter from "@/components/NewGameTime/Teams/MyTeamsFilter.vue";

export default {
    name: "MyTeams",
    components: {
        Navbar,
        LeftMenu,
        Banner,
        LeftMenuSide,
        MyTeamsFilter,
    },
    data() {
        return {
            openTabSet: 2,
            search: require("../../../assets/Svg/Search.svg"),  
            teams: require("../../../assets/Svg/team.svg"),
            add: require("../../../assets/Svg/add.svg"),
        };
    },
    mounted() {
        this.$root.$on("openTabSetting", (id) => {
            this.openTabSet = id;
        });
    },
};
</script>