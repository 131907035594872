<template>
  <div class="account">
    <div class="copyrights">
      <p>{{ currYear }} &copy; Copyright. Who I Follow</p>
    </div>

    <div class="help">
      <div>
        <router-link to="/our-privacy-policy">Privacy Policy</router-link>
        •
        <router-link to="/our-terms-and-conditions"
          >Terms & Conditions</router-link
        >
        •
        <router-link class="privacy-policy" to="/csae-policy">
  <span class="text-capitalize">CSAE </span> Policy
</router-link>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthBottom",
  data() {
    return {
      currYear: new Date().getFullYear(),
    };
  },
};
</script>

<style>
</style>