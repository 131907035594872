<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#DeleteTask"
      ref="openModalDeleteTask"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="DeleteTask">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Are you sure you want to delete this task?</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalDeleteTask"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>This action cannot be undo.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">
              Cancel
            </button>
            <button type="button" class="btn btn-danger" @click="confirmDelete">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DeleteTaskModal",
  data() {
    return {
      selectedTaskForDeletion : {}
    };
  },
  methods:{
    close(){
      var elem = this.$refs.closeModalDeleteTask;
      elem.click();
    },
    async confirmDelete() {
      try {
        const response = await axios.post('/userTasks/deleteTask', {
           task_id: this.selectedTaskForDeletion.id,
       });

        if (response.data.statusCode ===200) {
          this.$root.$emit('closePanel');
          this.$root.$emit('taskDeleted');
          
         this.close();
          this.$alert('Task deleted successfully');
        } else {
          this.$alert.error('Failed to delete task');
        }
      } catch (error) {
        console.error('Error deleting task:', error);
        this.$alert('An error occurred while deleting the task');
      }
    },
  },
  mounted() {
    this.$root.$on("openDeleteTaskModal", (task) => {
      this.selectedTaskForDeletion = task;
      var elem = this.$refs.openModalDeleteTask;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
