<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="single-event-page bg-white p-3 mb-3 rounded">
          <EventGameTop  :events="events"
              :showLoader="showLoader" />
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{
                      active: currentTab === 'detail',
                      disabled: isLoaded.detail,
                    }" id="detail-tab" data-toggle="tab" data-target="#detail" type="button" role="tab" aria-controls="detail"
                    aria-selected="true" @click="selectTab('detail')">
                    Games
                  </button>
                </li>
                
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{
                      active: currentTab === 'message',
                      disabled: isLoaded.message,
                    }" id="message-tab" data-toggle="tab" data-target="#message" type="button" role="tab"
                    aria-controls="message" @click="selectTab('message')" aria-selected="false">
                    Message Board
                  </button>
                </li>
              </ul>

              <div class="tab-content mt-4" id="myTabContent">
                <div class="tab-pane fade show active" :class="{ 'show active': currentTab === 'detail' }" id="detail"
                  role="tabpanel" aria-labelledby="detail-tab">
                  <EventGames 
                    class="mt-2"
                    :tournamentGame="tournamentGame"
                    :showLoader="showLoader"
                    v-if="currentTab === 'detail'" 
                    />
                  
                </div>
                
                <div class="tab-pane fade" :class="{ 'show active': currentTab === 'message' }" id="message" role="tabpanel"
                  aria-labelledby="message-tab">
                  <AsscoaitionMessageBoard :eventId="eventId" :messageBodyData="messageBodyData" :messageTopics="messageTopics"
                    :showLoaderMessage="showLoaderMessage" :currentIsAdmin="currentIsAdmin" :currentIsUmpire="currentIsUmpire"
                    :association_id="Number(association_id)" :messagepage="messagepage" :msgLastPage="msgLastPage"
                    v-if="currentTab === 'message'" />
                </div>
              </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import EventGameTop from '../components/Tournaments/EventGameTop.vue';
import EventGames from '../components/Tournaments/EventGames.vue';
import AsscoaitionMessageBoard from "../components/Association/AsscoaitionMessageBoard.vue";



export default {
  name: "EventGame",
  data() {
    return {
      eventId: "",
      showLoader: 1,
      tournamentGame: [],
      events: {},
      currentIsAdmin: "0",
      currentIsUmpire: "0",
      tournament_id: 0,
      association_id: 0,
      isLoaded: {
        detail: false,
        message: false,
      },
      messagepage : 1,
      currentTab: "detail",
      messageTopics: [],
      msgLastPage: 1,
      messageBodyData: [],
      umpireData: [],
      showLoaderMessage: 1,



    };
  },
  components: {
    Navbar,
    Banner,
    LeftMenu,
    EventGameTop,
    EventGames,
    AsscoaitionMessageBoard,

  },
  methods: {
    handleMessageBoardTabClick() {
      this.$root.$emit("message-Board-tab-clicked");
    },
      async getTournamentDetail() {
        this.isLoaded.detail = true;
        this.isLoaded.message = true;
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const tournamentGuid = segment_array.pop();
      this.showLoader = 1;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}tournaments/getEventGames`,
          { event_guid: tournamentGuid }
        );

        if (response.data.statusCode === 200) {
          this.tournamentGame = response.data.data.tournamentGames && response.data.data.tournamentGames ? response.data.data.tournamentGames : [];
          this.events = response.data.data.teamEventData;
          this.eventId = this.events.id;
          this.association_id = this.events.association_id;
          this.currentIsAdmin = this.events.currentIsAdmin === "1" ? "1" : "0";
          this.currentIsUmpire = this.events.currentIsAdmin === "0" ? "0" : "1";
          this.showLoader = 0;
        } else if (response.data.statusCode === 404 || response.data.statusCode === 403) {
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
              this.showLoader = 0;
            } else if (response.data.statusCode === 400) {
          this.showLoader = 0;
          this.$alert("Tournament not found").then(() => {
            window.history.back();
          });
          } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
      } finally {
        this.showLoader = 0;
        this.isLoaded.detail = false;
        this.isLoaded.message = false;
      }
    },
    async getMessageBoards(topicId) {
      this.isLoaded.detail = true;
      this.isLoaded.team = true;
      this.isLoaded.tournament = true;
      this.isLoaded.umpire = true;
      this.isLoaded.message = true;
      this.showLoaderMessage = 1;
      if (topicId !== undefined && topicId !== null) {
            this.messageBodyData = [];
            this.messagepage= 1;
      }
      try {
        let url = `${process.env.VUE_APP_API_URL}associationEvent/getMessageBoards?event_id=${this.eventId}&page=${this.messagepage}&association_id=${this.association_id}`;
        if (topicId !== undefined && topicId !== null) {
          url += `&topic_id=${topicId}`;
        }

        const response = await axios.get(url);
        if (response.data.statusCode === 200) {
          const responseData = response.data.data.messageBoards.data;
          this.msgLastPage = response.data.data.messageBoards.last_page;

          // Clear the existing messageBodyData if a filter is applied
          
          if (this.messagepage > 1) {
            this.messageBodyData = [...this.messageBodyData, ...responseData];
          } else {
            this.messageBodyData = responseData;
          }
          // this.messageBodyData = this.messageBodyData.concat(responseData);
          this.showLoaderMessage = 0;
        } else if (response.data.statusCode === 404) {
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
              this.showLoaderMessage = 0;
            } else {
          console.error(
            "Failed to fetch message boards:",
            response.data.message || "Unknown error occurred"
          );
          this.showLoaderMessage = 0;
        }
      } catch (error) {
        this.showLoaderMessage = 0;
        console.error("Failed to fetch message boards:", error);
      }
      this.isLoaded.detail = false;
      this.isLoaded.team = false;
      this.isLoaded.tournament = false;
      this.isLoaded.umpire = false;
      this.isLoaded.message = false;
    },
    async getMessageTopics() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}associationEvent/getMessageTopics`
        );

        if (response.data.statusCode === 200) {
          this.messageTopics = response.data.data.messageTopics;
        } else {
          console.error(
            "Failed to fetch umpires:",
            response.data.message || "Unknown error occurred"
          );
        }
      } catch (error) {
        console.error("Failed to fetch umpires:", error);
      }
    },
    handleMessageBoardPagination() {
      this.messagepage++;
      this.getMessageBoards();
    },
    selectTab(tab) {
      this.currentTab = tab;
      if (tab === "detail") {
        this.getTournamentDetail();
      }
     
      if (tab === "message") {
        this.messagepage=1;
        this.messageBodyData =[]
        this.getMessageBoards();
        this.getMessageTopics();
      }
    },
    
  },
  mounted() {
    this.getTournamentDetail();
    this.$root.$on("fetchMesagesForTopics", (topic) => {
      this.getMessageBoards(topic);
    });
    this.$root.$on("getMessageBoards", () => {
      this.handleMessageBoardPagination();
    });
    this.$root.$on("fetchMesages", () => {
      this.getMessageBoards();
    });
  },
  
};
</script>