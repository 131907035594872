<template>
    <div class="setting-main-wrapper">
      <div class="shop-side-auth">
        <div class="privacy-policy-page mb-3">
          <div class="csae-standards">
            <h2 class="main-heading">Standards Against Child Sexual Abuse and Exploitation (CSAE)</h2>
            <h5 class="sub-heading">Our Commitment</h5>
            <p>
              We are committed to providing a safe and secure platform, free from child sexual abuse and exploitation (CSAE) content.
            </p>
  
            <h5 class="sub-heading">Prohibited Content</h5>
            <p>
              Any content that involves the exploitation of minors, including child sexual abuse material, grooming, or any related activities, is strictly prohibited on our platform.
            </p>
  
            <h5 class="sub-heading">Detection and Moderation</h5>
            <ul>
              <li>Use of AI-powered tools to detect prohibited content.</li>
              <li>Regular audits by human moderators.</li>
            </ul>
  
            <h5 class="sub-heading">Reporting Mechanisms</h5>
            <ul>
              <li>Users can report inappropriate content directly through the app.</li>
              <li>Reports are reviewed promptly by our moderation team.</li>
            </ul>
  
            <h5 class="sub-heading">Cooperation with Authorities</h5>
            <p>
              We fully cooperate with law enforcement and child protection organizations to report and remove CSAE-related content.
            </p>
  
            <h5 class="sub-heading">Consequences</h5>
            <p>
              Accounts involved in CSAE-related activities are banned immediately, and information is shared with legal authorities.
            </p>
  
            <h5 class="sub-heading">Contact</h5>
            <p>
              For questions or concerns, contact us at <a href="mailto:support@example.com">legal@whoifollow.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CsaePolicy",
  };
  </script>
  
  <style scoped>
  .setting-main-wrapper {
    padding: 20px;
  }
  
  .csae-standards {
    margin-top: 20px;
  }
  
  .csae-standards h2 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .csae-standards h3 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
  }
  
  .csae-standards p {
    font-size: 16px;
    margin-top: 5px;
  }
  
  .csae-standards ul {
    margin-left: 20px;
    list-style: disc;
  }
  
  .csae-standards a {
    color: #007bff;
    text-decoration: none;
  }
  
  .csae-standards a:hover {
    text-decoration: underline;
  }
  </style>
  