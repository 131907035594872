<template>
  <div class="filter-container">
    <!-- Year Dropdown -->
    <div class="filter-cotent">
  
      <!-- Additional Filter Buttons -->
      <button class="filter-button">Past Events</button>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'FollowingEventsFilter',
    data() {
      return {
        arrow_down: require("../../../assets/Svg/arrow-down.svg"),
      };
    },
  };
  </script>