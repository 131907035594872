<template>
  <div>
   
    <div
      class="sec-top d-flex justify-content-between"
      v-if="events && showLoader == 0"
    >
      <div class="left-part">
        <h4>{{ events.eventName }}</h4>
        <p class="asso-type">
          {{ events.association }} - {{ events.eventType }}
        </p>
        <div class="event-list-details event-info-wrap">
          <div class="event-date">
            <img src="../../assets/images/calendar-event.png" />
            <div class="content" v-if="events && events.exactStartDate && events.exactEndDate">
              <!-- <div v-if="events.exactStartDate.split(',')[1] === events.exactEndDate.split(',')[1]">
                {{ formatDate(events.exactStartDate.split(',')[0]) }} - {{ formatDate(events.exactEndDate) }}
              </div> -->
              <div>
                {{ formatDate(events.exactStartDate) }} - {{ formatDate(events.exactEndDate) }}
              </div>
            </div>
          </div>
          <p class="location-content" v-if="
                  (events.status == 1 &&
                   events.address != null &&
                   events.address != '' &&
                   events.address != 'null') ||
                    (events.city != null &&
                     events.city != '' &&
                     events.address != 'null') ||
                    (events.state != null &&
                     events.state != '' &&
                     events.state != 'null')
                ">
            <img src="../../assets/images/location.png" @click="openGoogleMap(events)" />
            <span class="content" @click="openGoogleMap(events)">
              <span class="content">{{ events.address }} {{ events.city }},
                {{ events.state }}</span>
            </span>
          </p>
        </div>
      </div>
      <div class="right-part asso-right-part">
       
        <p class="director" v-if="events.director_name">Director : {{ events.director_name }}</p>
        <p class="director" v-else>Director : N/A</p>

        <p class="num" v-if="events.director_phone"> {{ events.mob_code }} {{ events.director_phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') }}</p>
        <p class="num" v-else> N/A</p>

        <p class="email" v-if="events.director_email">{{ events.director_email }}</p>
        <p class="email" v-else>N/A</p>

      </div>
    </div>
    <div class="products-list-shim p-0" v-if="showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssociationEventsTop",
  components: {
    
  },
  data() {
    return {
      editEventOption: false,
    };
  },
  methods: {
  
    callModal(data){
      this.$root.$emit("updateEditEventDetailModal", data);
    },
    openEditEventOption() {
      this.editEventOption = !this.editEventOption;
    },
    onClickOutside() {
      this.editEventOption = false;
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    formatDate(dateString) {
    const date = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options); // Adjust 'undefined' to specify a locale if needed
  },
    callModalForSeedar(){
      this.$root.$emit("openManageEventSeedarsModal");
    }
  },
  props: {
    events: {
      type: Object,
      required: true,
      default: () => ({})
    },
    
    showLoader: {
      type: Number,
    },
   
  },
  mounted() {
  },
};
</script>
<style scoped>
.create-event {
  background-color: #F0F0F0;
}
</style>