<template>
  <div>
    <p class="live-stream-content d-none">
      This will publish the boxscores for live stream and live stream channel
      for this game will be available for users to view.
    </p>
    <div
      class="d-md-flex align-items-center justify-content-between footer-score"
    >

     

      <div>
        <content-placeholders
          class="post-view mt-3 w-100"
          :rounded="true"
          v-if="showShimmer == 1 || gameActionLoader == 1"
        >
          <content-placeholders-text :lines="4" />
        </content-placeholders>
        <div class="score" v-if="
        gameActionLoader == 0 &&
        this.scores.length != 0 &&
        gameFetchedright.game_delayed != 1 &&
        gameFetchedright.locked != 1 &&
        (String(gameFetchedright.scoring_user_id) === String(this.user_id) || gameFetchedright.scoring_user_id === null) &&
        gameFetchedright.status != 2
      ">

      
          <div class="inning-counter d-flex flex-column justify-content-center">
            <p>Inning</p>
            <span>{{ totalInnings }}</span>
          </div>
          <div class="team-position">
            <p class="team-name text-capitalize">{{ currentBattingTeam }}</p>
            <div class="team-score-hr">

              <div class="team-score">
                <span class="head">Runs</span>

                <div class="product-inc-dec">
                  <div
                    class="product-decrement d-block"
                    @click="runsDecrement()"
                  >
                    <img src="../../../assets/images/minus.png" alt="" />
                  </div>
                  <div class="product-value pt-1">
                    <span>{{ runs }}</span>
                  </div>
                  <div class="product-increment" @click="runsIncrement()">
                    <img src="../../../assets/images/plus.png" alt="" />
                  </div>
                </div>
              </div>

              <div class="team-hr">
                <span class="head">HR</span>
                <div class="product-inc-dec">
                  <div class="product-decrement d-block" @click="hrDecrement()">
                    <img src="../../../assets/images/minus.png" alt="" />
                  </div>
                  <div class="product-value pt-1">
                    <span>{{ hr }}</span>
                  </div>
                  <div class="product-increment" @click="hrIncrement()">
                    <img src="../../../assets/images/plus.png" alt="" />
                  </div>
                </div>
              </div>

            </div>

            <div class="team-score-hr mt-3">

              <div class="team-score">
                <span class="head">Strikes</span>

                <div class="product-inc-dec">
                  <div
                    class="product-decrement d-block"
                    @click="strikesDecrement()"
                  >
                    <img src="../../../assets/images/minus.png" alt="" />
                  </div>
                  <div class="product-value pt-1">
                    <span>{{ strikes }}</span>
                  </div>
                  <div class="product-increment" @click="strikesIncrement()">
                    <img src="../../../assets/images/plus.png" alt="" />
                  </div>
                </div>
              </div>

              <div class="team-hr">
                <span class="head">Balls</span>
                <div class="product-inc-dec">
                  <div class="product-decrement d-block" @click="ballsDecrement()">
                    <img src="../../../assets/images/minus.png" alt="" />
                  </div>
                  <div class="product-value pt-1">
                    <span>{{ balls }}</span>
                  </div>
                  <div class="product-increment" @click="ballsIncrement()">
                    <img src="../../../assets/images/plus.png" alt="" />
                  </div>
                </div>
              </div>

            </div>

            <div class="team-score-hr mt-3">
              <div class="team-score">
                <span class="head">Outs</span>

                <div class="product-inc-dec">
                  <div
                    class="product-decrement d-block"
                    @click="outsDecrement()"
                  >
                    <img src="../../../assets/images/minus.png" alt="" />
                  </div>
                  <div class="product-value pt-1">
                    <span>{{ outs }}</span>
                  </div>
                  <div class="product-increment" @click="outsIncrement()">
                    <img src="../../../assets/images/plus.png" alt="" />
                  </div>
                </div>
              </div>

              <div class="team-hr">
                <button class="btn next" @click="nextBatter()">Next Batter</button>
                
              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-if="
      gameActionLoader == 0 &&
      this.scores.length != 0 &&
      gameFetchedright.game_delayed != 1 &&
      gameFetchedright.locked != 1 &&
      (String(gameFetchedright.scoring_user_id) === String(this.user_id) || gameFetchedright.scoring_user_id === null) &&
      gameFetchedright.status != 2
">
        <div class="row mt-3 mt-lg-0">
          <div class="col-12 col-lg-6 mb-2 px-3 px-lg-0">
            <div class="live-stream-tags h-100" @click="endHalfInning">
              <h6>End Half Inning</h6>
              <p>End this half inning and start the next</p>
            </div>
          </div>
          <div
            class="col-12 col-lg-6 pl-lg-2 mb-2"
          >
            <div class="live-stream-tags h-100" @click="swapHomeVisitor">
              <h6>Swap Home / Visitor</h6>
              <p>Swap home / visitor team & reset game</p>
            </div>
          </div>
          <div
            class="col-12 col-lg-6 px-lg-0 pb-2 pb-lg-0"
          >
            <div class="live-stream-tags h-100" @click="deleteLastInning">
              <h6>Delete Last Inning</h6>
              <p>Delete the last inning scoring data</p>
            </div>
          </div>
          <div
              class="col-12 col-lg-6 pl-lg-2"
              v-if="gameFetchedright.status != 2 && (String(gameFetchedright.scoring_user_id) === String(this.user_id) || gameFetchedright.scoring_user_id === null)"
          >
            <div
              class="live-stream-tags mr-0 mb-0 live-stream-tags-danger h-100"
              @click="endGame"
            >
              <h6>End Game</h6>
              <p>Ends the game and lock the team scores</p>
            </div>
          </div>
        </div>
      </div>
      <content-placeholders
          class="post-view mt-3 pl-5"
          :rounded="true"
          v-else-if=" gameActionLoader == 1"
        >
          <content-placeholders-text :lines="4" />
        </content-placeholders>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ScoreRunsTabs",
  props: {
    currentIsAdmin: {
      type: String,
    },
    currentIsUmpire: {
      type: String,
    },
    gameFetchedright: Object,
    totalInnings: Number,
    currentBattingTeam: String,
    currentTeamType: Number,
    currentTeamHR: {},
    currentTeamRuns: {},
    showShimmer: Number,
    currentInningVisitScore: Object,
    currentInningVisitHR: Object,
    currentInningHomeScore: Object,
    currentInningHomeHR: Object,
    homeTeamName: String,
    visitTeamName: String,
    scores: {
      type: Array,
    },
    singleGame: {
      type: Object,
    },
  },
  data() {
    return {
      current_inning_type: "",
      strikes: 0,
      balls: 0,
      outs: 0,
      runs: 0,
      hr: 0,
      isGroundImageLoaded: false,
      gameActionLoader: 0,
      canvaLoader: 0,
      unUsualdata: {},
      user_id : localStorage.getItem("userId"),
    };
  },
  computed: {
    formattedNumber() {
      // Check for the last digit to determine the suffix
      const lastDigit = this.totalInnings % 10;

      // Handle special cases for 11th, 12th, and 13th
      if (this.totalInnings >= 11 && this.totalInnings <= 13) {
        return `${this.totalInnings}th`;
      }

      // Assign the appropriate suffix based on the last digit
      switch (lastDigit) {
        case 1:
          return `${this.totalInnings}st`;
        case 2:
          return `${this.totalInnings}nd`;
        case 3:
          return `${this.totalInnings}rd`;
        default:
          return `${this.totalInnings}th`;
      }
    },
  },
  methods: {
    callStartGameModal: function () {
      this.$root.$emit("openSelectHomeTeamModal", this.gameFetchedright);
    },
    endHalfInning: function () {
      var lastMessage = "";
      var lastVisitScore = "";
      if (this.currentTeamType == 2) {
        lastMessage = `End of ${this.formattedNumber} Inning`;
        lastVisitScore = `${this.visitTeamName}\n ${this.currentInningVisitScore.score} R , ${this.currentInningVisitHR.score} HR\n${this.currentBattingTeam}\n ${this.runs} R, ${this.hr} HR\n`;
      } else {
        lastMessage = `Middle of ${this.formattedNumber} Inning`;
        lastVisitScore = `${this.currentBattingTeam}\n ${this.runs} R, ${this.hr} HR\n`;
      }
      this.$fire({
        title: lastMessage,
        html: `<div>${lastVisitScore.replace(/\n/g, "<br>")}</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "End Half Inning",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.gameActionLoader = 1;
          var formData = new FormData();
          formData.append("tournament_game_id", this.gameFetchedright.id);
          formData.append("association_id", this.singleGame.association_id);
          axios
            .post(
              process.env.VUE_APP_API_URL + "tournaments/game/endHalfInning",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode == 300) {
                alert(response.data.message);
              } else if (response.data.statusCode == 404) {
                this.$alert(response.data.message, "Error");
                this.$router.push({ name: "Teams" });
              } else if (response.data.statusCode == 500) {
                alert(response.data.message);
              } else {
                let loader = this.$loading.show({
                  container: this.fullPage ? null : this.$refs.formContainer,

                  color: "#ffffff",
                  backgroundColor: "#333",
                  opacity: 0.6,
                });

                loader.hide();
                // this.gameActionLoader = 0;
                this.$root.$emit("showTounamentGameScore");
              }
            });
        }
      });
    },
    swapHomeVisitor: function () {
      var swapMessage = `Swap Home & Visitor team will erase\n all game data.\n${this.visitTeamName} will become the Home team.`;
      this.$fire({
        title: "Swap Home/ Visitor",
        html: `<div>${swapMessage.replace(/\n/g, "<br>")}</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.gameActionLoader = 1;
          var formData = new FormData();
          formData.append("tournament_game_id", this.gameFetchedright.id);
          formData.append("association_id", this.singleGame.association_id);
          axios
            .post(
              process.env.VUE_APP_API_URL + "tournaments/game/swapGameTeams",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode == 300) {
                alert(response.data.message);
              } else if (response.data.statusCode == 404) {
                this.$alert(response.data.message, "Error");
                this.$router.push({ name: "Teams" });
              } else if (response.data.statusCode == 500) {
                alert(response.data.message);
              } else {
                let loader = this.$loading.show({
                  container: this.fullPage ? null : this.$refs.formContainer,

                  color: "#ffffff",
                  backgroundColor: "#333",
                  opacity: 0.6,
                });
                // this.gameActionLoader = 0;
                loader.hide();
                this.$root.$emit("showTounamentGameScore");
              }
            });
        }
      });
    },
    deleteLastInning: function () {
      this.$confirm(
        "Are you sure to delete this inning,\n You will not be able to revert this action. Continue ?",
        "Delete Inning."
      ).then(() => {
        this.gameActionLoader = 1;
        var formData = new FormData();
        formData.append("tournament_game_id", this.gameFetchedright.id);
        formData.append("association_id", this.singleGame.association_id);
        axios
          .post(
            process.env.VUE_APP_API_URL + "tournaments/game/deleteLastInning",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 300) {
              alert(response.data.message);
            } else if (response.data.statusCode == 404) {
                this.$alert(response.data.message, "Error");
                this.$router.push({ name: "Teams" });
            } else if (response.data.statusCode == 500) {
              alert(response.data.message);
            } else {
              let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,

                color: "#ffffff",
                backgroundColor: "#333",
                opacity: 0.6,
              });
              // this.getScores();

              loader.hide();
              // this.gameActionLoader = 0;
              this.$root.$emit("showTounamentGameScore");
            }
          });
      });
    },
    endGame: function () {
      this.$confirm(
        "Are you sure to end this Game, You will not be able to revert this action. Continue ?",
        "End Game."
      ).then(() => {
        this.gameActionLoader = 1;
        var formData = new FormData();
        formData.append("tournament_game_id", this.gameFetchedright.id);
        formData.append("association_id", this.singleGame.association_id);
        axios
          .post(
            process.env.VUE_APP_API_URL + "tournaments/game/endGame",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 300) {
              this.$alert(response.data.message);
            } else if (response.data.statusCode == 404) {
                this.$alert(response.data.message, "Error");
                this.$router.push({ name: "Teams" });
            } else if (response.data.statusCode == 500) {
              this.$alert(response.data.message);
            } else {
              let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,

                color: "#ffffff",
                backgroundColor: "#333",
                opacity: 0.6,
              });
              // this.getScores();

              loader.hide();
              // this.gameActionLoader = 0;
              this.$root.$emit("showTounamentGameScore");
            }
          });
      });
    },
    restartGame: function () {
      this.$confirm(
        "Are you sure to restart this Game. Continue ?",
        "Restart Game."
      ).then(() => {
        this.gameActionLoader = 1;
        var formData = new FormData();
        formData.append("tournament_game_id", this.gameFetchedright.id);
        formData.append("association_id", this.singleGame.association_id);
        axios
          .post(
            process.env.VUE_APP_API_URL + "tournaments/game/reopenGame",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 300) {
              this.$alert(response.data.message);
            } else if (response.data.statusCode == 404) {
                this.$alert(response.data.message, "Error");
                this.$router.push({ name: "Teams" });
              } else if (response.data.statusCode == 500) {
              this.$alert(response.data.message);
            } else if (response.data.statusCode == 403) {
              this.$alert(response.data.message);
              window.location.reload();
            }  else {
              let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,

                color: "#ffffff",
                backgroundColor: "#333",
                opacity: 0.6,
              });
              // this.getScores();
              this.gameActionLoader = 0;
              loader.hide();
              this.$root.$emit("showTounamentGameScore");
            }
          });
      });
    },
    nextBatter: function () {
      this.$confirm(
        "Are you sure you want to reset strikes, balls and outs. Continue ?",
        "Alert."
      ).then(() => {
        this.gameActionLoader = 1;
        var formData = new FormData();
        formData.append("tournament_game_id", this.gameFetchedright.id);
        formData.append("association_id", this.singleGame.association_id);
        formData.append("team_type", this.currentTeamType);
        formData.append("inning_no", this.totalInnings);
        formData.append("inning_type", this.current_inning_type);

        axios
          .post(
            process.env.VUE_APP_API_URL + "tournaments/game/nextBatter",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 300) {
              this.$alert(response.data.message);
            } else if (response.data.statusCode == 404) {
                this.$alert(response.data.message, "Error");
                this.$router.push({ name: "Teams" });
              } else if (response.data.statusCode == 500) {
              this.$alert(response.data.message);
            } else if (response.data.statusCode == 403) {
              this.$alert(response.data.message);
              window.location.reload();
            }  else {
              let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,

                color: "#ffffff",
                backgroundColor: "#333",
                opacity: 0.6,
              });
              // this.getScores();
              this.gameActionLoader = 0;
              loader.hide();
              this.$root.$emit("showTounamentGameScore");
            }
          });
      });
    },

    strikesIncrement() {
      this.strikes = this.strikes + 1;
      this.strikesUpdate();
    },

    strikesDecrement() {
      var newStrikes = this.strikes - 1;

      if (newStrikes >= 0) {
        this.strikes = this.strikes - 1;
        this.strikesUpdate();
      }
    },

    ballsIncrement() {
      this.balls = this.balls + 1;
      this.strikesUpdate();
    },

    ballsDecrement() {
      var newBalls = this.balls - 1;

      if (newBalls >= 0) {
        this.balls = this.balls - 1;
        this.strikesUpdate();
      }
    },

    outsIncrement() {
      this.outs = this.outs + 1;
      this.strikesUpdate();
    },

    outsDecrement() {
      var newOuts = this.outs - 1;

      if (newOuts >= 0) {
        this.outs = this.outs - 1;
        this.strikesUpdate();
      }
    },

    runsIncrement() {
      this.runs = this.runs + 1;
      this.$emit("passData", this.runs);
      this.scoreUpdate(this.runs, 2);
    },

    runsDecrement() {
      var newRuns = this.runs - 1;

      if (newRuns >= 0) {
        this.runs = this.runs - 1;
        this.scoreUpdate(this.runs, 2);
      }
      this.$emit("passData", this.runs);
    },

    hrIncrement() {
      this.hr = this.hr + 1;
      this.$emit("passHrData", this.hr);
      this.scoreUpdate(this.hr, 1);
    },

    hrDecrement() {
      var newHr = this.hr - 1;

      if (newHr >= 0) {
        this.hr = this.hr - 1;
        this.scoreUpdate(this.hr, 1);
      }

      this.$emit("passHrData", this.hr);
    },
    async scoreUpdate(score, inning_type) {
    var formData = new FormData();
    formData.append("score", score);
    formData.append("tournament_game_id", this.gameFetchedright.id);
    formData.append("team_type", this.currentTeamType);
    formData.append("inning_no", this.totalInnings);
    formData.append("inning_type", inning_type);
    formData.append("strikes", this.strikes);
    formData.append("balls", this.balls);
    formData.append("outs", this.outs);
    formData.append("association_id", this.singleGame.association_id);
    
    await axios
      .post(
        process.env.VUE_APP_API_URL + "tournaments/game/updateScore",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 404) {
              this.$alert(response.data.message, "Error");
              this.$router.push({ name: "Teams" });
            } 
      })
      .catch((error) => {
        console.error(error);
        // Optionally handle other errors here
      });
  },
  async strikesUpdate() {
    var formData = new FormData();
    formData.append("score", this.runs);
    formData.append("tournament_game_id", this.gameFetchedright.id);
    formData.append("team_type", this.currentTeamType);
    formData.append("inning_no", this.totalInnings);
    formData.append("inning_type", 2);
    formData.append("strikes", this.strikes);
    formData.append("balls", this.balls);
    formData.append("outs", this.outs);
    formData.append("association_id", this.singleGame.association_id);
    
    await axios
      .post(
        process.env.VUE_APP_API_URL + "tournaments/game/updateScore",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 404) {
              this.$alert(response.data.message, "Error");
              this.$router.push({ name: "Teams" });
            } 
      })
      .catch((error) => {
        console.error(error);
        // Optionally handle other errors here
      });
  },

  },
  mounted() {
    this.$root.$on("updateScoreHrTournament", (homeRun, score, strikes, balls, outs, inning_type) => {
    this.runs = score;
    this.hr = homeRun;
    this.strikes = strikes;
    this.balls = balls;
    this.outs = outs;
    this.current_inning_type = inning_type === 2 ? 2 : 2;
    console.log("current_inning_type", this.current_inning_type);
  });

  this.$root.$on("restart-game", () => {
    this.restartGame();
  });
},
};
</script>
<style scoped>
.next {
  background-color: #F0F0F0;
  font-size: 16px;
  -webkit-border-radius: 5px;
}
</style>