<template>
  <div>
    <Navbar />
    <inningModal />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3 pb-3">
        <div class="no-gutters">
          <div class="products-top">
            <div class="d-flex align-items-center justify-content-between">
              <h5>Box Scores</h5>
              <div class="d-flex align-items-center event-tab22">
                <ul
                  class="nav nav-tabs mr-1 mr-md-3 mb-sm-0"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <a
                      v-bind:class="[
                        myBox == 1 ? 'nav-link1 active' : 'nav-link1',
                      ]"
                      to="/box-scores"
                      @click="showMyScore()"
                    >
                      My Box Scores
                    </a>
                  </li>

                  <li class="nav-item" role="presentation">
                    <a
                      v-bind:class="[
                        allBox == 1 ? 'nav-link1 active' : 'nav-link1',
                      ]"
                      @click="showAllScore()"
                    >
                      All Box Scores
                    </a>
                  </li>
                </ul>

                <ul class="nav nav-tabs box-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      v-bind:class="[
                        eventList == 1 ? 'nav-link active' : 'nav-link',
                      ]"
                      id="list-tab"
                      data-toggle="tab"
                      data-target="#list"
                      role="tab"
                      aria-controls="list"
                      aria-selected="true"
                    >
                      <img
                        src="../assets/images/list-blue.png"
                        alt=""
                        class="box-image-blue"
                      />
                      <img
                        src="../assets/images/list-black.png"
                        alt=""
                        class="box-image-black"
                      />
                    </a>
                  </li>

                  <li class="nav-item" role="presentation">
                    <a
                      v-bind:class="[
                        eventGrid == 1 ? 'nav-link active' : 'nav-link',
                      ]"
                      id="grid-tab"
                      data-toggle="tab"
                      data-target="#grid"
                      role="tab"
                      aria-controls="grid"
                      aria-selected="false"
                    >
                      <img src="../assets/images/box-score-list.png"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="d-sm-flex align-items-center mt-2" style="gap: 10px">
              <v-select
                :options="ageOptions"
                v-model="allFilters.ageFilter"
                @input="filterObject()"
                :placeholder="agePlaceholder"
              >
                <template v-slot:prepend>
                  <img src="../assets/images/arrow-down.png" alt="" />
                </template>
              </v-select>
              <v-select
                :options="genderOptions"
                v-model="allFilters.genderFilter"
                @input="filterObject()"
                :placeholder="genderPlaceholder"
              ></v-select>
              <v-select
                :options="associationOptions"
                v-model="allFilters.associationFilter"
                @input="filterObject()"
                :placeholder="associationPlaceholder"
              ></v-select>
              <v-select
                :options="stateOptions"
                v-model="allFilters.stateFilter"
                @input="filterObject()"
                :placeholder="statePlaceholder"
              ></v-select>
              <v-select
                :options="yearOptions"
                v-model="allFilters.yearFilter"
                @input="filterObject()"
                :placeholder="yearPlaceholder"
              ></v-select>
            </div>
          </div>

          <div class="calender-card m-0">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="list"
                role="tabpanel"
                aria-labelledby="list-tab"
              >
                <!-- <MyBoxScores :gameScores="gameScores" /> -->
                <eventView
                  v-if="allBox == 0 || myBox == 1"
                  v-bind:TeamDetails="eventsDatatoView"
                  v-bind:UserDetails="UserDetails"
                  v-bind:showLoader="eventShowLoader"
                  :currentUserId="user_id"
                />
                <allEventView
                  v-if="allBox == 1 || myBox == 0"
                  v-bind:TeamDetails="eventsDatatoViewAll"
                  v-bind:UserDetails="UserDetails"
                  v-bind:showLoader="eventShowLoader"
                  :currentUserId="user_id"
                />
              </div>
              <div
                class="tab-pane fade"
                id="grid"
                role="tabpanel"
                aria-labelledby="grid-tab"
              >
                <eventListView
                  v-if="allBox == 0 || myBox == 1"
                  v-bind:TeamDetails="eventsDatatoView"
                  v-bind:UserDetails="UserDetails"
                  v-bind:showLoader="eventShowLoader"
                  :currentUserId="user_id"
                />
                <allEventListView
                  v-if="allBox == 1 || myBox == 0"
                  v-bind:TeamDetails="eventsDatatoViewAll"
                  v-bind:UserDetails="UserDetails"
                  v-bind:showLoader="eventShowLoader"
                  :currentUserId="user_id"
                />
              </div>
              <div id="overlay">
                <div class="box-score-right-side">
                  <viewRightSide />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import inningModal from "../components/BoxScores/inningModal.vue";
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import eventView from "../components/BoxScores/eventView.vue";
import eventListView from "../components/BoxScores/eventListView.vue";
import viewRightSide from "../components/BoxScores/viewRightSide.vue";
import allEventView from "../components/BoxScores/allEventView.vue";
import Banner from "../components/BannerNotify/BannerPage.vue"
import allEventListView from "../components/BoxScores/allEventListView.vue";
import * as firestoreService from "@/database/firestore";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "BoxScores",
  components: {
    Navbar,
    LeftMenu,
    eventView,
    eventListView,
    allEventView,
    allEventListView,
    viewRightSide,
    inningModal,
    Banner
  },
  data: function () {
    return {
      TeamDetails: [],
      UserDetails: [],
      TeamIDS: [],
      myBox: 1,
      allBox: 0,
      current: 1,
      past: 0,
      eventList: 1,
      eventGrid: 0,
      showLoader: 1,
      eventShowLoader: 0,
      gameScores: [],
      user_id: "",
      yearOptions: ["2022", "2023", "2024", "2025"],
      yearPlaceholder: "Enter Year",
      stateOptions: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "MontanaNebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "PennsylvaniaRhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Virgin Islands",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      statePlaceholder: "Enter State",
      associationOptions: [],
      associationPlaceholder: "Enter Association",
      genderOptions: ["Male", "Female", "Coed"],
      genderPlaceholder: "Enter Gender",
      ageOptions: [],
      agePlaceholder: "Enter Age",
      allFilters: {
        ageFilter: "",
        associationFilter: "",
        genderFilter: "",
        stateFilter: "",
        yearFilter: "",
      },
      eventsDatatoView: [],
      total: 0,
      eventTo: 0,
      noEvent: 0,
      lastPage: 1,
      eventsDatatoViewAll: [],
      page: 1,
    };
  },
  created() {
    window.onscroll = () => {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight - 3;
      if (bottomOfWindow) {
        this.handleScrolledToButtom();
      }
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("events", ["setEventListDataScoreBox"]),
    ...mapActions("events", ["setEventListDataScoreBoxAll"]),
    ...mapActions("events", ["getCurrentComingStatus"]),
    filterObject() {
      this.page = 1;
      this.eventsData(this.TeamDetails, 1);
      this.allEvents(this.TeamDetails, 1);
    },
    handleScrolledToButtom() {
      if (this.page >= this.lastPage) {
        return;
      }
      this.page++;
      this.eventsData(this.TeamDetails);
      this.allEvents(this.TeamDetails);
    },
    async firebasedata(user_id) {
      this.TeamDetails = [];
      const querySport = firestoreService.roomsSportQuery(user_id);
      const { data, docs } = await firestoreService.getRooms(querySport);
      this.totalTeams = docs[docs.length - 1];
      data.forEach((teams) => {
        this.TeamDetails.push(teams.id);
        //  Make Array for all Users on Team Start -----------
        const rawUsers = [];
        teams.users.forEach((userId) => {
          const promise = firestoreService.getUser(userId);
          rawUsers.push(promise);
        });
        this.teamUsers = "";
        Promise.all(rawUsers).then((values) => {
          let teamDeta = {
            teamID: teams.id,
            users: values,
          };
          this.UserDetails.push(teamDeta);
        });
      });
      let comingStatus = {
        teamID: this.TeamDetails,
        usersDetail: this.UserDetails,
        users: user_id,
      };
      this.getCurrentComingStatus(comingStatus);
      this.eventsData(this.TeamDetails);
      this.allEvents(this.TeamDetails);
    },
    getAgeGroup() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAgeGroup`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ageOptions = [];
            response.data.data.list.forEach((element) => {
              this.ageOptions.push(element.name);
            });
          }
        });
    },
    getAssociation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociation?search=${""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationOptions = [];
          response.data.data.association.forEach((element) => {
            this.associationOptions.push(element.association_name);
          });
        });
    },
    async eventsData(TeamDetails, dataSwitch = 0) {
      this.eventShowLoader = 1;
      const formData = new FormData();
      if (TeamDetails) {
        formData.append("sportsRoomID", TeamDetails);
        formData.append("filterQuery", JSON.stringify(this.allFilters));
        axios
          .post(
            process.env.VUE_APP_API_URL +
              `event/scoreBoxEventList?page=${this.page}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((res) => {
            this.total = res.data.data.total;
            this.eventTo = res.data.data.to;
            if (dataSwitch == 1) {
              this.eventsDatatoView = res.data.data.data;
            } else {
              this.eventsDatatoView.push(...res.data.data.data);
            }

            this.lastPage = res.data.data.last_page;
            this.noEvent = res.data.data.data.length > 0 ? 0 : 1;
            this.eventShowLoader = 0;
          });
      }
    },
    async allEvents(TeamDetails, dataSwitch = 0) {
      this.eventShowLoader = 1;
      const formData = new FormData();
      formData.append("sportsRoomID", TeamDetails);
      formData.append("filterQuery", JSON.stringify(this.allFilters));
      axios
        .post(
          process.env.VUE_APP_API_URL +
            `event/scoreBoxEventListAll?page=${this.page}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((res) => {
          this.total = res.data.data.total;
          this.eventTo = res.data.data.to;
          if (dataSwitch == 1) {
            this.eventsDatatoViewAll = res.data.data.data;
          } else {
            this.eventsDatatoViewAll.push(...res.data.data.data);
          }
          this.lastPage = res.data.data.last_page;
          this.noEvent = res.data.data.data.length > 0 ? 0 : 1;
          this.eventShowLoader = 0;
        });
    },
    async getList() {
      this.TeamIDS = [];

      if (this.myBox == 1) {
        const querySport = firestoreService.roomsSportQuery(this.user_id);
        const { data, docs } = await firestoreService.getRooms(querySport);
        this.totalTeams = docs[docs.length - 1];
        data.forEach((teams) => {
          this.TeamIDS.push(teams.id);
          this.eventShowLoader = 0;
        });
      }
    },

    showMyScore() {
      this.myBox = 1;
      this.allBox = 0;
      this.getList();
    },
    showAllScore() {
      this.myBox = 0;
      this.allBox = 1;
      this.getList();
    },
    showEventListView() {
      this.eventList = 1;
      this.eventGrid = 0;

      // this.getList();
    },
    showEventGridView() {
      this.eventList = 0;
      this.eventGrid = 1;
      this.getList();
    },
    async getUserChatId(){
      if(this.user)
       {
        const promiseUser = await firestoreService.getUserCid(this.user.id.toString());
        this.user_id = promiseUser.data[0].id;
        this.firebasedata(this.user_id);
      } else {
        setTimeout(() => {
          this.getUserChatId();
        }, 1000); 
      }
    
    }
  },

  mounted() {
    // if (this.user) {
    //   if (this.user.pro_status == null) {
    //     this.$router.push({ name: "GoPro" });
    //   }
    // }
    this.getUserChatId();
    this.getAgeGroup();
    this.getAssociation();
    this.getList();
  },
};
</script>

<style scoped>
.calender-card {
  margin-top: 15px;
}

.event-tab22 ul {
  list-style-type: none;
  display: flex;
  margin-bottom: 0;
}

.event-tab22 ul li a {
  color: #188ef5;
  cursor: pointer;
  background-color: #e5f2fe;
  text-align: center;
}

.event-tab22 ul li a img {
  width: 20px;
}

.event-tab22 .nav-tabs .nav-item:first-child .nav-link {
  border-radius: 5px 0 0 5px !important;
}

.event-tab22 .nav-tabs .nav-item:last-child .nav-link {
  border-radius: 0 5px 5px 0 !important;
}

.event-tab22 .nav-tabs .nav-item:first-child .nav-link1 {
  border-radius: 5px 0 0 5px !important;
}

.event-tab22 .nav-tabs .nav-item:last-child .nav-link1 {
  border-radius: 0 5px 5px 0 !important;
}

.event-tab22 .nav-tabs {
  border-bottom: 0 !important;
}

.event-tab22 .nav-tabs .nav-item.show .nav-link,
.event-tab22 .nav-tabs .nav-link.active {
  background-color: #188ef5 !important;
  border-radius: inherit !important;
  color: #ffffff !important;
}

.event-tab22 .nav-tabs .nav-item.show .nav-link1,
.event-tab22 .nav-tabs .nav-link1.active {
  background-color: #188ef5 !important;
  border-radius: inherit !important;
  color: #ffffff !important;
}

.event-tab22 .nav-tabs .nav-link {
  border: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5f2fe;
}

.event-tab22 .nav-tabs .nav-link1 {
  border: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: 0 !important;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 576px) {
  .products-top h5 {
    font-size: 12px !important;
  }
  .event-tab22 .nav-tabs .nav-link {
    font-size: 9px;
    padding: 0.2rem 0.4rem;
  }
  .event-tab22 .nav-tabs .nav-link1 {
    font-size: 9px;
    padding: 0.2rem 0.4rem;
  }
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  cursor: pointer;
}

.order-history-right-side {
  z-index: 9999;
  right: 0;
  top: 0;
  height: 100vh;
}
</style>