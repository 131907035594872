<template>
  <div class="row no-gutters">
    <div
      class="col-12"
      :class="{
        'col-md-9': panel,
        'p-25': panel,
      }"
    >
      <div class="task-input-wrap">
        <h6>Tasks</h6>
        <form @submit.prevent>
          <input
            ref="taskInput"
            type="text"
            placeholder="Add a task"
            v-model="newTask.taskTitle"
            class="user-input"
            @keyup.enter="addTask()"
            maxlength="30"
          />
          <div class="event-btns-wrapper justify-content-between">
            <div
              class="input-group date"
              id="startDateCheck"
              data-target-input="nearest"
            >
            <label for="startDate">Set Due Date</label>
              <input
                class="form-control"
                id="startDate"
                type="date"
                placeholder="Add due date"
                v-model="newTask.dueDate"
              />
            </div>
            <button
              type="button"
              class="btn btn-primary add-task-btn"
              @click="addTask()"
              :disabled="isTaskEmpty"
            >
              <div v-if="isLoading" class="lds-dual-ring"></div>
              <span v-else>Add</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TaskInput",
  data() {
    return {
      newTask: {
        taskTitle: "",
        dueDate: "",
        isCompleted: false,
        isImportant: false,
      },
      isLoading: false,
    };
  },
  props: {
    panel: {
      type: Boolean,
    },
  },
  computed: {
    isTaskEmpty() {
      return this.newTask.taskTitle.trim() === "";
    },
  },
  methods: {
    async addTask() {
      this.isLoading = true;
      try {
        // Make an API call to add the task
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}userTasks/store`,
          this.newTask
        );
        console.log(response)
        // Emit the added task (including the response data from the API)
        this.$root.$emit("add-task");

        // Reset the input field
        this.newTask.taskTitle = "";
        this.newTask.dueDate = "";
      } catch (error) {
        console.error("Error adding task:", error);
        this.$alert("Failed to add task. Please try again.");
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.$refs.taskInput.focus();
  },
};
</script>
